<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div class="row q-col-gutter-md">
    <div class="col-12 col-md-6">
      <GlassCard class="q-pa-xs">
        <CampusPlusLogo size="64" style="float: right; margin-right: -10px; margin-left: 10px; margin-top: -10px" />
        <h1 class="q-mb-md">{{ $t("portal.start.welcome.heading") }}</h1>
        <p class="q-mb-sm">
          {{ $t("portal.start.welcome.body") }}
        </p>

        <div class="q-mt-lg q-mb-none">
          <h5>{{ $t("portal.start.labels.newsletter") }}:</h5>
          <i18n-t keypath="portal.start.newsletter.body" tag="p" scope="global"><a
              href="https://www.lists.kit.edu/sympa/subscribe/campusplus">{{ $t("portal.start.newsletter.name")
              }}</a></i18n-t>
        </div>

        <q-btn :icon="symOutlinedImportContacts" size="md" no-caps color="blue" class="q-mb-md"
          :to="{ name: 'SMUProceduresList' }">&nbsp;{{ $t("portal.gotosignmeup") }}</q-btn>

        <p v-if="!isLoggedIn">
          {{ $t("portal.start.login.requiredText") }}<br />
          <q-btn color="teal" href="/api/user/oidc-login" class="q-mt-sm q-mr-sm" :icon="symOutlinedLogin"
            :label="t('portal.start.login.loginKIT')"></q-btn>
          <q-btn color="purple" :to="{ name: 'Login' }" class="q-mt-sm q-mr-sm" :icon="symOutlinedLogin"
            :label="t('portal.start.login.loginExternal')"></q-btn>
          <q-btn color="indigo" :to="{ name: 'Register' }" class="q-mt-sm" :icon="symOutlinedAppRegistration"
            :label="t('portal.start.login.register')"></q-btn>
        </p>
      </GlassCard>
    </div>
    <div class="col-12 col-md-6">
      <GlassCard class="q-pa-xs">
        <h2 class="q-mb-sm">{{ $t("common.labels.changes") }}</h2>
        <h5>Version 2.15: SignMeUp: Bearbeiten von Weiteren Angaben und Themenbewertungen bei Anmeldungen
        </h5>
        <div class="text-caption text-bold" style="--color-highlight-2: #ff0000">
          11.02.2025
        </div>
        <p>
          Als Anbieter einer Veranstaltung können Sie nun bestehende Anmeldungen editieren, um die Weiteren Angaben oder
          Themenbewertungen anzupassen (z.B. wenn Sie eine Person manuell angemeldet haben). Der Editierbutton steht am
          Ende der Zeile in der Anmeldungen-Liste wenn Ihr Verfahren Weitere Angaben oder Themen/Termine
          nutzt.<br /><br />
        </p>

        <h5>Version 2.14: BZM+: Übermittlung von Zulassungsquoten, SignMeUp: Konfiguration der Benachrichtigungssprache
        </h5>
        <div class="text-caption text-bold" style="--color-highlight-2: #ff0000">
          10.02.2025
        </div>
        <p>
          In BZM+ können Auswahlkoordinator/innen nun die Zulassungsquoten für Ihre Studiengänge über BZM+ in einem
          vereinfachten Verfahren mitteilen. Sie finden die Option in der Liste Ihrer Studienangebote und wählen einfach
          die gewünschten Studienangebote aus.<br /><br />
          In SignMeUp kann nun die Sprache der Benachrichtigungen konfiguriert werden. Die Sprache kann für jede
          Veranstaltung einzeln festgelegt werden. Die Sprache der Benachrichtigungen wird dann für alle
          Teilnehmer/innen dieser Veranstaltung verwendet. Die Option findet sich unter den Weiteren Angaben in den
          Einstellungen.<br /><br />
        </p>

        <h5>Version 2.13: SignMeUp: Verbessertes Löschen von Themen und Terminen</h5>
        <div class="text-caption text-bold" style="--color-highlight-2: #ff0000">
          24.01.2025
        </div>
        <p>
          Mit diesem Update wurde das
          Verhalten beim Löschen von Terminen überarbeitet und es ist jetzt klarer, dass nur eine Löschmarkierung
          gesetzt wird und das Löschen mit dem Speichern erfolgt. Termine, welche noch zugewiesene Anmeldungen haben,
          können nicht mehr zum Löschen ausgewählt werden.
          Außerdem wurde die Einträge in das Protokoll
          verbessert.<br /><br />

        </p>

        <h5>Version 2.12.0: SignMeUp: Themen-spezifische Zusagetexte</h5>
        <div class="text-caption text-bold" style="--color-highlight-2: #ff0000">
          22.01.2025
        </div>
        <p>
          Ab sofort können Sie an Themen und Terminen spezifische Texte für eine Zusage mit diesem Thema oder Termin
          festlegen. Der Teilnehmer bekommt diesen Text dann in der Status-Benachrichtigung angezeigt, wenn er dem Thema
          oder Termin zugewiesen wurde. Der Text ist ergänzend zum allgemeinen, anpassbaren Zusagetext.<br />
          So können Sie z.B. Einladungslink themenspezifisch verschicken.<br /><br />

        </p>



        <p class="q-mt-sm">
          {{ $t("portal.start.changes.allchangestext") }}
          <a href=" https://docs.campus.kit.edu/updates/" target="_blank" class="text-blue text-bold">{{
            $t("portal.start.changes.versinfo")
          }}</a>.
        </p>
      </GlassCard>
    </div>
    <!-- <div>
        <q-btn class=" q-ma-xs" color="primary">Primary</q-btn>
              <q-btn class="q-ma-xs" color="secondary">secondary</q-btn>
              <q-btn class="q-ma-xs" color="accent">accent</q-btn>
              <q-btn class="q-ma-xs" color="positive">positive</q-btn>
              <q-btn class="q-ma-xs" color="negative">negative</q-btn>
              <q-btn class="q-ma-xs" color="info">info</q-btn>
              <q-btn class="q-ma-xs" color="warning">warning</q-btn>
              <q-btn class="q-ma-xs" color="main">main</q-btn>
              <q-btn class="q-ma-xs" color="content">content</q-btn>
              <q-btn class="q-ma-xs" color="content-2">content-2</q-btn><br />
              <q-btn class="q-ma-xs" color="pantone-illuminating">pantone-illuminating</q-btn>
              <q-btn class="q-ma-xs" color="pantone-illuminating-complementary">pantone-illuminating-c</q-btn>
              <q-btn class="q-ma-xs" color="pantone-classic-blue">pantone-classic-blue</q-btn>
              <q-btn class="q-ma-xs" color="pantone-classic-blue-complementary">pantone-classic-blue-c</q-btn>
              <q-btn class="q-ma-xs" color="pantone-kingfisher-blue">pantone-kingfisher-blue</q-btn>
              <q-btn class="q-ma-xs" color="pantone-kingfisher-blue-complementary">pantone-kingfisher-blue-c</q-btn>
              <q-btn class="q-ma-xs" color="pantone-very-peri">pantone-very-peri</q-btn>
              <q-btn class="q-ma-xs" color="campus-yellow">campus-yellow</q-btn>
      </div> -->
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import CampusPlusLogo from "@/components/shared/CampusPlusLogo.vue";
import useUserProfile from "@/composables/useUserProfile";
import {
  symOutlinedAppRegistration,
  symOutlinedImportContacts,
  symOutlinedList,
  symOutlinedLogin,
} from "@quasar/extras/material-symbols-outlined";
import { useTitle } from "@vueuse/core";

import GlassCard from "../components/shared/GlassCard.vue";
import MiniCard from "../components/shared/MiniCard.vue";

const { t } = useI18n();
const { isLoggedIn } = useUserProfile();

useTitle(computed(() => "Campus Plus Portal - " + t("portal.start.welcome.label")));
</script>
