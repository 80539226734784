{
  "common": {
    "labels": {
      "additionalactions": "Weitere Aktionen",
      "merge": "Zusammenführen",
      "undelete": "Löschmarkierung entfernen"
    },
    "terms": {
      "addperson": "Person hinzufügen",
      "apply": "Übernehmen",
      "execute": "Ausführen",
      "invalidvalue": "Ungültige Angabe",
      "object": "Objekt"
    }
  },
  "components": {
    "campuseventselect": {
      "campuseventhint": "Eine verknüpfte Campus-Veranstaltung ermöglicht den ILIAS-Export, die Anzeige von Informationen aus der Veranstaltung sowie einen automatischen Hinweis zum Anmeldeverfahren im öffentlichen VVZ",
      "selectevent": "Veranstaltung auswählen"
    },
    "iliasexportsuccessdialog": {
      "intro": "Ihre Teilnehmer wurden erfolgreich entsprechend Ihrer Einstellungen nach ILIAS übertragen. Über die Kurs-Links im Bereich \"Exportmöglichkeiten\" können Sie die Mitgliederlisten der jeweiligen Kurse begutachten.",
      "title": "ILIAS Export erfolgreich"
    },
    "localuserselect": {
      "hint": "Hinweis: Sie können nur nach Personen suchen, welche sich mindestens einmal im Portal angemeldet haben."
    },
    "usageprogress": {
      "acceptances": "Zusagen",
      "offerings": "Teilnahme-Angebote"
    }
  },
  "dialogs": {
    "assignGroup": {
      "message": "Möchten Sie die Gruppe {name} wirklich den {count} ausgewählten Anmeldeverfahren zuweisen? ",
      "title": "Gruppe zuweisen"
    },
    "assignGroupTemplate": {
      "message": "Möchten Sie die Gruppe {name} wirklich den {count} ausgewählten Vorlagen zuweisen? "
    },
    "deleteGroup": {
      "message": "Möchten Sie das die Gruppe {name} wirklich löschen?<br />Es werden keine Anmeldeverfahren gelöscht."
    },
    "deleteProcedures": {
      "message": "Über die Liste können nur Anmeldeverfahren gelöscht werden, welche nicht veröffentlicht sind und keine Anmeldungen besitzen.<br /><br />Von den ausgewählten Verfahren erfüllen <strong>{count} von {total} Verfahren</strong> diese Voraussetzungen.<br /><br />Möchten Sie diese jetzt löschen?",
      "novalidprocedures": "Über die Liste können zum Schutz vor versehentlichem Löschen nur Anmeldeverfahren gelöscht werden, welche nicht veröffentlicht sind und keine Anmeldungen besitzen. <br /><br />Derzeit haben Sie keine zulässigen Verfahren ausgewählt. Bitte löschen Sie Verfahren mit Anmeldungen direkt aus dem Verfahren heraus."
    },
    "deleteTemplates": {
      "message": "Möchten Sie die ausgewählten {count} Vorlagen wirklich löschen?"
    },
    "deleteTopic": {
      "message": "Möchten Sie das Thema/den Termin {name} wirklich löschen?<br />Hierbei gehen auch alle bisher abgegebenen Bewertungen sowie Zuweisungen verloren."
    },
    "removeGroup": {
      "message": "Möchten Sie die Gruppe {name} von den {count} ausgewählten Anmeldeverfahren wirklich entfernen?",
      "title": "Gruppe entfernen"
    },
    "removeGroupTemplate": {
      "message": "Möchten Sie die Gruppe {name} aus den {count} ausgewählten Vorlagen wirklich entfernen?"
    },
    "renewaccessid": {
      "message": "Möchten Sie die Adresse des privaten Zugriffslinks wirklich neu generieren? Die alte Adresse verliert ihre Gültigkeit."
    },
    "toggleVisibility": {
      "message": "Möchten Sie die Sichtbarkeit aller ausgewählten Anmeldeverfahren auf <strong>{visibility}</strong> ändern?"
    }
  },
  "fields": {
    "activity": {
      "type": {
        "values": {
          "BoostActivate": "Boost für Anmeldung aktiviert",
          "BoostDeactivate": "Boost für Anmeldung deaktiviert",
          "FcfsAccept": "FCFS: Platz erhalten",
          "Lottery": "Losverfahren durchgeführt",
          "LotteryAccept": "Platz durch Losverfahren erhalten",
          "ManagerRegistrationMerge": "{targetUser} wurde zur Lerngruppe von {description} hinzugefügt.",
          "ManagerRegistrationSplit": "{targetUser} wurde aus der Lerngruppe von {description} entfernt.",
          "ManagerSignupAdd": "{targetUser} wurde durch Verwalter/in angemeldet.",
          "ProcedureAccessChanged": "Zugriffseinstellungen geändert",
          "ProcedureAdd": "Anmeldeverfahren angelegt",
          "ProcedureEdit": "Einstellungen geändert",
          "ProcedureGroupAssigned": "Die Gruppe {description} wurde zugewiesen",
          "ProcedureGroupRemoved": "Die Gruppe {description} wurde entfernt",
          "ProcedureManagerAdded": "Zugriffsrechte an {targetUser} vergeben",
          "ProcedureManagerDeleted": "Zugriffsrechte von {targetUser} entzogen",
          "ProcedureTopicAdded": "Thema {description} hinzugefügt",
          "ProcedureTopicDeleted": "Thema {description} gelöscht",
          "ProcedureTopicOrderChanged": "Themen-Reihenfolge geändert",
          "ProcedureTopicUpdated": "Thema {description} aktualisiert",
          "RenewProcedureAccessId": "Privater Zugriffslink aktualisiert",
          "SignupAdd": "Neue Anmeldung",
          "SignupAddTopic": "Neue Anmeldung (ausgewählt: {description})",
          "SignupConfirmed": "Die Teilnahme wurde bestätigt",
          "SignupEdit": "Anmeldung geändert",
          "SignupJoin": "Lerngruppe beigetreten",
          "SignupRemove": "Abmeldung durchgeführt",
          "SportRankingLottery": "Fachsemester-Ranking Verfahren durchgeführt",
          "SportRankingLotteryAccept": "Platz über Fachsemester-Ranking Verfahren erhalten",
          "StateChange": "Anmeldestatus von Bewerbungen geändert",
          "StateDeclineUnconfirmed": "Das Angebot an {targetUser} wurde nach Fristüberschreitung automatisch abgelehnt.",
          "TemplateAdd": "Neue Vorlage angelegt"
        }
      }
    },
    "group": {
      "allowedBoostCount": {
        "label": "Boosts pro Sem."
      },
      "allowedConfirmationCount": {
        "label": "Max. Bestätigungen pro Sem."
      },
      "allowedRegistrationCount": {
        "label": "Max. Anmeldungen pro Sem."
      },
      "name": {
        "label": "Name"
      },
      "procedureCount": {
        "label": "Verknüpfte Verfahren"
      },
      "shortName": {
        "invalid": "Die Bezeichnung darf nur aus Buchstaben und Zahlen bestehen"
      },
      "shortname": {
        "label": "Kurzbezeichnung"
      },
      "visibility": {
        "label": "Sichtbarkeit",
        "values": {
          "0": {
            "description": "Die Gruppe ist öffentlich sichtbar und kann im Angebot als Filter verwendet werden.",
            "label": "Öffentlich"
          },
          "1": {
            "description": "Die Gruppe wird nicht im Angebot aufgeführt, ist aber in den Details zu Anmeldebeschränkungen sichtbar.",
            "label": "Anzeige von Beschränkungen"
          },
          "2": {
            "description": "Die Gruppe ist nur für die Verwalter/innen sichtbar, nützlich wenn Sie die Gruppe nur für Berechtigungen verwenden.",
            "label": "Versteckt"
          }
        }
      }
    },
    "optimization": {
      "status": {
        "FEASIBLE": "Zulässig 🤔",
        "INFEASIBLE": "Keine Lösung 😞",
        "OPTIMAL": "Optimal 🎉"
      }
    },
    "procedure": {
      "allocationType": {
        "abbr": {
          "FirstComeFirstServed": "FCFS",
          "Lottery": "Losverfahren",
          "Manual": "Manuell",
          "Optimization": "Optimierung",
          "SportRanking": "FS-Ranking",
          "none": "k.A."
        },
        "descriptions": {
          "FirstComeFirstServed": "First Come First Served, auch als Windhundverfahren bekannt, vergibt Plätze nach Eingangszeitpunkt der Bewerbung. Um Überbuchungen durch gleichzeitigen Eingang zu vermeiden, legt das System daher alle Anmeldungen zuerst im Status Offen an, und vergibt anschließend verfügbare Plätze nach Reihenfolge in der Datenbank. Dadurch ist sichergestellt, dass Sie nicht plötzlich mit mehr Zusagen als verfügbaren Plätzen konfrontiert werden.<br /><br />In diesem Verfahren wird derzeit keine automatische Vergabe von Themen oder Terminen unterstützt. Sie können jedoch manuell den Zusagen Themen oder Termine zuweisen.<br /><br /><strong>Bitte beachten Sie:</strong> First Come First Served benachteiligt Interessenten, welche zum Anmeldestart zeitlich oder technisch verhindert sind (z.B. Auslandsaufenthalt, Urlaub, ...) erheblich. Bitte überlegen Sie, ob daher ein anderes Verfahren (z.B. Losverfahren) gerade bei hoher Nachfrage nicht ebenso funktionieren würde.",
          "Lottery": "Das Losverfahren nimmt den Zeitdruck aus dem Windhundverfahren/First Come First Served und erlaubt es Interessenten sich innerhalb des Anmeldezeitraums entspannt anzumelden. Zum festgelegten Loszeitpunkt werden die Plätze unter allen eingegangenen Anmeldungen verlost.<br />Anschließend wird die Anmeldung wie First Come First Served behandelt, d.h. bei freie Plätzen erhalten neue Anmeldungen unmittelbar eine Zusage und bei frei werdenden Plätzen werden diese aus der Warteliste (wenn vorhanden) bis zum Nachrückende besetzt. Ein manueller Eingriff ist nicht notwendig. ",
          "Manual": "In der Anmeldeliste Ihres Verfahren finden Sie alle eingegangenen Anmeldungen. Dort können entweder über das Hinzufügen der Spalten oder die Detailansicht die erfolgten Themenbewertungen (falls vorhanden) und gemachten Angaben (falls abgefragt) eingesehen werden. Anschließend können Sie über die Status- und Themen-Dropdowns (Ansicht 1) oder die Status- und Themenbuttons (Ansicht 2) eine Auswahl treffen und entscheiden, ob Teilnehmer ein Teilnahme-Angebot (muss bestätigt werden) oder eine Zusage (muss nicht mehr bestätigt werden) erhalten. Es kann auch der Status Warteliste verwendet werden um potentielle Nachrücker zu informieren, allerdings erfolgt kein automatisches Nachrücken.<br /><br />Durch Speichern der Änderungen werden die Statusänderungen wirksam und Studierende informiert. Bei Absage/nicht fristgerechter Bestätigung durch Studierende werden alle eingetragenen Dozierenden per E-Mail über den freigewordenen Platz benachrichtigt. Eine automatische Nachbesetzung erfolgt nicht.",
          "Optimization": "Bei der optimalen Verteilung müssen alle Bewerber eine Bewertung von Themen, Terminen oder Veranstaltungen bei der Anmeldung abgeben. Nach Ende des Anmeldezeitraums können Sie dann die optimale Verteilung ausführen. Hierbei wird lineare Optimierung genutzt, um alle (oder falls das Platzangebot nicht genügt: möglichst viele) Anmeldungen nach der jeweiligen Präferenz auf die verfügbaren Themen/Termine zu verteilen.<br /><br /> Dieses Verfahren versucht die Gesamtzufriedenheit, welches die durchschnittliche Zufriedenheit mit der Terminzuweisung jedes einzelnen Teilnehmers, zu maximieren.<br /><br />Nach der Optimierung sehen Sie wie die Verteilung und Zufriedenheit aussieht und können ggf. Anpassungen an den verfügbaren Plätzen vornehmen.",
          "SportRanking": "Dieses Verfahren nutzt eine Rangliste (basierende auf Fachsemester, Regelstudienzeit und Loszahl bei Gleichstand) um freie Plätze zu vergeben. Hierbei werden Plätze initial durch die Rangliste, anschließend durch die Warteliste und neue Anmeldungen erneut durch eine Ranglistenberechnung vergeben."
        },
        "explanations": {
          "FirstComeFirstServed": "Die Plätze werden automatisch nach Eingangszeitpunkt vergeben.",
          "Lottery": "Zum Loszeitpunkt werden die Plätze unter allen eingegangenen Anmeldungen verlost. Nach der Verlosung werden freie Plätze nach Eingangszeitpunkt vergeben.",
          "Manual": "Der oder die Dozierende trifft eine Teilnehmerauswahl.",
          "Optimization": "Die Plätze und Zuweisungen werden anhand der abgegebenen Bewertungen optimal verteilt.",
          "SportRanking": "Die Plätze werden nach einem Rankingverfahren auf Basis des Fachsemesters und der Regelstudienzeit vergeben."
        },
        "label": "Vergabeverfahren",
        "managerhints": {
          "FirstComeFirstServed": "Auch bekannt als Windhundprinzip werden die Plätze hier nach Eingangszeitpunkt der Bewerbung vergeben. Dies ist für Bewerber bei beliebten Veranstaltungen oft mit erheblichen Nachteilen verbunden.",
          "Lottery": "Zu einem von Ihnen festlegten Zeitpunkt werden unter allen Bewerbern die verfügbaren Plätze verlost. Ein Nachrücken erfolgt dann nach dem Windhundprinzip.",
          "Manual": "Sie können in der Teilnehmerliste Zusagen und Absagen vergeben. Hierbei können Sie die bei der Anmeldung hinterlegten Angaben berücksichtigen.",
          "Optimization": "Alle Bewerber werden anhand ihrer Präferenzen auf eine Menge von Terminen, Themen oder Veranstaltungen optimal verteilt.",
          "SportRanking": "Bildet zum angegebenen Zeitpunkt eine Rangliste basierend auf dem Fachsemester und der Regelstudienzeit und vergibt die Plätze nach dieser. In der Nachrückphase werden die Plätze nach der Rangliste und weitere Plätze nach Verlosung vergeben."
        },
        "recommendations": {
          "FirstComeFirstServed": "Veranstaltungen mit begrenzter oder unbegrenzter Platzzahl und mittlerer Nachfrage, z.B. Klausureinsichten.",
          "Lottery": "Veranstaltungen mit begrenzter Platzzahl und oftmals großer Nachfrage, z.B. Seminare oder Sprachkurse.",
          "Manual": "Seminare oder kleine Veranstaltungen bei denen Sie möglichst passende Teilnehmer finden möchten.",
          "Optimization": "Große Veranstaltungen, bei denen eine Vielzahl von Terminen oder Themen zur Verfügung steht und Sie die die Interessenten möglichst optimal verteilen möchten, z.B. Tutorien.",
          "SportRanking": "Veranstaltungen, in welchen Studierende in höheren Fachsemestern bevorzugt werden sollen."
        },
        "values": {
          "0": "Manuelle Auswahl",
          "1": "Optimierte Verteilung",
          "2": "Losverfahren",
          "3": "First Come First Served",
          "4": "Fachsemester-Ranking",
          "FirstComeFirstServed": "First Come First Served",
          "Lottery": "Losverfahren",
          "Manual": "Manuelle Auswahl",
          "Optimization": "Optimierte Verteilung",
          "SportRanking": "Fachsemester-Ranking",
          "null": "keine Angabe"
        }
      },
      "closingDate": "Nachrückende",
      "eventType": {
        "label": "Art der Veranstaltung",
        "values": {
          "Appointment": "Terminvereinbarung",
          "BonusExam": "Bonusklausur",
          "Course": "Kurs",
          "Exam": "Prüfung",
          "Lecture": "Vorlesung",
          "Other": "Anderes",
          "Practical": "Praktikum",
          "Review": "Klausureinsicht",
          "Seminar": "Seminar",
          "Tutorial": "Tutorium",
          "Workshop": "Tagesworkshop"
        }
      },
      "firstDrawDate": "Nächste geplante Verlosung",
      "mailLanguage": {
        "label": "Sprache der Benachrichtigung",
        "values": {
          "de": "nur Deutsch",
          "deen": "Zweisprachig (Deutsch+Englisch)",
          "en": "nur Englisch"
        }
      },
      "questions": {
        "types": {
          "0": "Textfeld (einzeilig)",
          "1": "Textfeld (mehrzeilig)",
          "2": "Zahlenangabe",
          "3": "Dateiupload (PDF)",
          "4": "Auswahl (einfach)",
          "5": "Auswahl (mehrfach)"
        }
      },
      "registrationEnd": "Anmeldeende",
      "registrationStart": "Anmeldebeginn",
      "restrictionDegree": {
        "values": {
          "1": "Bachelor",
          "16": "ohne Abschluss (Mitarbeitende)",
          "2": "Master",
          "4": "Diplom",
          "8": "Zeitstudium"
        }
      },
      "restrictionUserType": {
        "values": {
          "1": "Externe Personen",
          "2": "Studierende (KIT)",
          "4": "Gäste & Partner",
          "8": "Mitarbeitende"
        }
      },
      "studyGroupSize": "Lerngruppengröße",
      "visibility": {
        "label": "Sichtbarkeit",
        "values": {
          "None": "Unveröffentlicht",
          "Private": "Privat",
          "Public": "Öffentlich"
        }
      }
    },
    "proceduretopic": {
      "dayOfWeek": {
        "label": "Wochentag",
        "values": {
          "Friday": {
            "long": "Freitag",
            "short": "Fr"
          },
          "Monday": {
            "long": "Montag",
            "short": "Mo"
          },
          "Saturday": {
            "long": "Samstag",
            "short": "Sa"
          },
          "Sunday": {
            "long": "Sonntag",
            "short": "So"
          },
          "Thursday": {
            "long": "Donnerstag",
            "short": "Do"
          },
          "Tuesday": {
            "long": "Dienstag",
            "short": "Di"
          },
          "Wednesday": {
            "long": "Mittwoch",
            "short": "Mi"
          }
        }
      },
      "type": {
        "label": "Typ",
        "values": {
          "Event": "Veranstaltung",
          "SingleAppointment": "Einzel-Termin",
          "Topic": "Thema",
          "WeeklyAppointment": "Wöchentl. Termin"
        }
      }
    },
    "registration": {
      "state": {
        "details": {
          "accepted": "Sie haben für die Veranstaltung eine Zusage erhalten und dürfen an der Veranstaltung teilnehmen. Bitte melden Sie sich rechtzeitig ab, falls Sie an der Veranstaltung nicht teilnehmen können.",
          "declined": "Leider konnte Ihnen für diese Veranstaltung kein Platz angeboten werden.",
          "offer": "Sie haben ein Teilnahmeangebot für diese Veranstaltung erhalten. Die Teilnahme muss innerhalb der angegeben Frist bestätigt werden, andersfalls verfällt das Angebot.",
          "open": "Ihre Anmeldung ist eingegangen, bisher liegt noch keine Entscheidung über eine Teilnahme vor. Bei einigen Vergabeverfahren erfolgt die Platzvergabe zu einem gemeinsamen Zeitpunkt.",
          "waitlist": "Sie befinden sich auf der Warteliste für diese Veranstaltung. Sollten Plätze frei werden, werden diese aus der Warteliste nachbesetzt."
        },
        "values": {
          "0": "Offen",
          "1": "Warteliste",
          "2": "Angebot",
          "3": "Zusage",
          "4": "Absage",
          "accepted": "Zusage",
          "declined": "Absage",
          "offer": "Angebot",
          "open": "Offen",
          "waitlist": "Warteliste"
        }
      }
    }
  },
  "group": {
    "edit": {
      "access": "Zugriff",
      "confirmationrestriction": "Bestätigungsbeschränkung",
      "intro1": "Gruppen dienen dazu mehrere Anmeldeverfahren zusammenzufassen, um diese inhaltlich oder strukturell als eine Einheit zu markieren. Außerdem können für Gruppen Anmelderestriktionen (maximal X Anmeldungen über alle Anmeldeverfahren in dieser Gruppe hinweg) festgelegt werden.",
      "intro2": "Gruppen sind semesterübergreifend gültig, die Anmeldebeschränkung wird jedoch pro Semester ausgewertet.",
      "maxboostsperterm": "Max. Boosts im Semester",
      "maxconfirmperterm": "Max. Bestätigungen im Semester",
      "maxregperterm": "Max. Anmeldungen im Semester",
      "namehint": "Dieser Name wird in der öffentlichen Sicht des Anmeldeverfahrens angezeigt.",
      "newgroup": "Neue Gruppe",
      "product": "Studiengang",
      "registrationrestriction": "Anmeldebeschränkung",
      "shortnamehint": "Die Kurzbezeichnung wird in der Verwaltungsoberfläche und beim Import von Anmeldeverfahren verwendet. Sie muss eindeutig sein.",
      "unitshort": "OE",
      "visibilityhint": "Steuert die Sichtbarkeit der Gruppe im Angebot"
    },
    "labels": {
      "allunspecified": "Alle nicht überschriebenen"
    }
  },
  "import": {
    "errors": {
      "duplicateprocedure": "Es existiert bereits ein Anmeldeverfahren mit identischen Einstellungen, jedoch hat dieser Eintrag noch keine Id und würde doppelt angelegt werden. Haben Sie evtl. eine alte Liste verwendet?",
      "invalidfield": {
        "campusEventId": "Zur angegebenen VL-Nummer existiert keine Veranstaltung oder Sie haben keinen Zugriff auf diese Veranstaltung",
        "closingdate": "Das Nachrückende darf nicht vor dem Anmeldeende liegen",
        "eventtype": "Die angegebene Art der Veranstaltung ist ungültig",
        "group": "Das angegebene Gruppenkürzel existiert nicht oder Sie haben keinen Zugriff",
        "heading": "Es wurde keine Überschrift angegeben oder die Überschrift ist ungültig",
        "id": "Die angegebene Id existiert nicht oder Sie besitzen keinen vollen Zugriff auf dieses Verfahren",
        "name": "Es muss ein Name für das Verfahren angegeben werden",
        "primarycontactmail": "Es muss eine Kontakt-E-Mail-Adresse angegeben werden",
        "registrationend": "Das Anmeldeende muss angegeben werden und nach dem Anmeldebeginn liegen",
        "registrationstart": "Der Anmeldebeginn muss angegeben werden"
      },
      "missingfield": {
        "name": "Das Feld Name muss ausgefüllt werden."
      }
    }
  },
  "labels": {
    "addproduct": "Studiengang hinzufügen",
    "allocationType": "Vergabe",
    "assignedTopic": "Thema/Termin",
    "biweekly": "zweiwöchentl.",
    "createdOn": "Anmeldung",
    "currently": "derzeit",
    "deadline": "Frist",
    "degrees": "Abschlüsse",
    "firstdrawdate": "Los-/Vergabezeitpunkt",
    "group": "Gruppe",
    "groups": "Gruppen",
    "happiness": "Zufriedenheit",
    "place": "Platz",
    "registration": "Anmeldung",
    "removefavorite": "Favorit entfernen",
    "resetFilters": "Alle Filter zurücksetzen",
    "sortmode": "Sortiermodus",
    "star": "Stern",
    "stars": "Sterne",
    "state": "Status",
    "title": "Titel",
    "type": "Art",
    "unit": "Einrichtung",
    "unlimited": "unbeschränkt",
    "usage": "Belegung",
    "visibility": "Sichtb.",
    "weekly": "wöchentl."
  },
  "list": {
    "labels": {
      "group": "Veranstaltungsgruppe",
      "heading": "Einordnung im Vorlesungsverzeichnis",
      "offer": "Angebot",
      "offering": "Angebot",
      "refresh": "Aktualisieren",
      "showDifferentTerm": "Laufende Anmeldeverfahren aus anderen Semestern anzeigen",
      "showEndedProcedures": "Beendete Verfahren anzeigen"
    },
    "messages": {
      "noresults": "Es wurden leider keine Veranstaltungen mit den ausgewählten Parametern gefunden.",
      "notloggedinwarning": "Sie sind nicht eingeloggt. Möglicherweise sehen Sie nicht alle verfügbaren Veranstaltungen. Bitte melden Sie sich zur Veranstaltungsanmeldung über den Button oben rechts mit Ihrem KIT-Login oder als externer Nutzer an."
    }
  },
  "menu": {
    "labels": {
      "campusvvztree": "Campus VVZ Baum",
      "manage": "Verwalten",
      "myregistrations": "Meine Anmeldungen",
      "offer": "Angebot",
      "offering": "Angebot"
    }
  },
  "myregistrations": {
    "additionalinfotopic": "Zusätzliche Informationen zum zugewiesenen Thema oder Termin",
    "boostintro": "Eine oder mehrere Ihrer Anmeldungen erlauben die Vergabe von Boosts 🚀, um diese Anmeldungen zu priorisieren und die Chance auf einen Platz zu erhöhen.",
    "boostsdoc": "Weitere Informationen zu Boosts",
    "confirmparticipation": "Teilnahme bestätigen",
    "confirmparticipationerror": {
      "deadlinepassed": "Die Frist zur Bestätigung der Teilnahme ist abgelaufen.",
      "grouplimitexceeded": "Sie haben die maximale Anzahl an Teilnahmebestätigungen innerhalb der folgenden Gruppe(n) erreicht. Sie müssen sich zuerst von einer anderen Veranstaltung innerhalb dieser Gruppe abmelden, um die Teilnahme für diese Veranstaltung bestätigen zu können.",
      "intro": "Eine Teilnahmebestätigung ist nicht möglich",
      "notfound": "Die gewünschte Anmeldung wurde nicht gefunden.",
      "unauthorized": "Sie sind nicht berechtigt, die Teilnahme für diese Anmeldung zu bestätigen."
    },
    "confirmparticipationmessage": "Möchten Sie die Teilnahme an der Veranstaltung <strong>{0}</strong> bestätigen?",
    "confirmparticipationmessageext": "<br />Dies gilt auch für die Mitglieder Ihrer Lerngruppe.",
    "emptylist": "In diesem Semester liegen noch keine Anmeldungen von Ihnen vor. Schauen Sie doch einfach mal ins",
    "pendingoffer": "Sie haben ein Teilnahmeangebot für diese Veranstaltung. Sie müssen die Teilnahme bis {0} bestätigen, um Ihren Platz nicht zu verlieren.",
    "unregister": {
      "accepted": "Sie haben derzeit eine Zusage, welche durch die Abmeldung erlischt.",
      "button_yes": "Ja, Abmelden",
      "founder": "Sie sind der Gründer dieser Lerngruppe. Durch die Abmeldung wird ein anderes Mitglied der Lerngruppe Gründer.",
      "intro": "Möchten Sie sich von der oben genannten Veranstaltung wirklich abmelden?",
      "offer": "Sie haben derzeit ein Teilnahmeangebot, welches durch die Abmeldung erlischt.",
      "reregisternotpossible": "Eine neue Anmeldung ist nicht mehr möglich, da der Anmeldezeitraum abgelaufen ist.",
      "reregisterpossible": "Eine neue Anmeldung ist möglich, solange der Anmeldezeitraum noch läuft.",
      "title": "Abmeldung",
      "waitlist": "Sie befinden sich derzeit auf der Warteliste. Durch die Abmeldung verlieren Sie Ihren Wartelistenplatz."
    }
  },
  "notifications": {
    "campusvvz": {
      "guidcopied": "Die GUID für die Überschrift {0} wurde in die Zwischenablage kopiert."
    },
    "exportilias": {
      "loading": "Der Export wird durchgeführt. Abhängig von der Teilnehmeranzahl kann dies bis zu mehrere Minuten dauern. Sie werden informiert, wenn der Export abgeschlossen ist."
    },
    "group": {
      "copied": "Der Link zum Angebot für die Gruppe {0} wurde in die Zwischenablage kopiert.",
      "create": {
        "success": "Die Gruppe wurde erfolgreich angelegt"
      },
      "edit": {
        "error": "Es ist ein allgemeiner Fehler aufgetreten. Bitte kontaktieren Sie den Support.",
        "notallowed": "Sie sind nicht berechtigt, diese Gruppe zu bearbeiten",
        "notfound": "Die zu bearbeitende Gruppe existiert nicht mehr",
        "shortnameconflict": "Die angegebene Kurzbezeichnung wird bereits für eine andere Gruppe verwendet"
      },
      "update": {
        "success": "Die Gruppe wurde aktualisiert"
      }
    },
    "iliasexport": {
      "error": "Beim Export ist leider ein Fehler aufgetreten. Sie können den Export erneut versuchen oder sich an Support wenden."
    },
    "iliasvalidation": {
      "error": "Beim Überprüfen der ILIAS Konfiguration ist ein Fehler aufgetreten."
    },
    "procedure": {
      "allocationrun": {
        "success": "Die Vergabeprozesse wurden für die ausgewählten Verfahren gestartet. Es kann einige Minuten dauern bis alle Prozesse durchgelaufen sind."
      },
      "autosave": {
        "error": "Beim automatischen Speichern ist ein Fehler aufgetreten."
      },
      "copy": {
        "error": "Die URL konnte nicht in die Zwischenablage kopiert werden.",
        "success": "Die URL wurde in Ihre Zwischenablage kopiert."
      },
      "deleted": {
        "success": "Die ausgewählten, zulässigen Anmeldeverfahren wurden gelöscht"
      },
      "duplicate": {
        "error": "Beim Kopieren ist ein allgemeiner Fehler aufgetreten. Bitte wenden Sie sich bei Fragen an den Support.",
        "notallowed": "Sie sind nicht berechtigt, das Verfahren zu kopieren.",
        "notfound": "Das angegebene Verfahren wurde nicht gefunden.",
        "success": "Das Verfahren wurde erfolgreich kopiert."
      },
      "edit": {
        "error": "Es ist ein allgemeines Problem beim Aufruf des Anmeldeverfahrens aufgetreten.",
        "notallowed": "Sie sind nicht berechtigt, dieses Verfahren zu bearbeiten.",
        "notfound": "Das angegebene Verfahren existiert nicht."
      },
      "fakeRegistrationsCreated": "Testbewerbungen wurden erfolgreich erzeugt",
      "group": {
        "assign": {
          "success": "Die Gruppe wurden den ausgewählten Anmeldeverfahren zugewiesen"
        },
        "delete": {
          "success": "Die Gruppe {name} wurde gelöscht"
        },
        "remove": {
          "success": "Die Gruppenzuweisungen wurden entfernt"
        }
      },
      "join": {
        "success": "Sie sind der Lerngruppe erfolgreich beigetreten und finden diese in der Liste Ihrer Anmeldungen."
      },
      "manager": {
        "add": {
          "alreadyexists": "Die Person ist bereits in der Liste der zugriffsberechtigten Personen enthalten",
          "badrequest": "Die Person konnte nicht hinzugefügt werden",
          "failed": "Beim Hinzufügen ist ein allgemeiner Fehler aufgetreten.",
          "notallowed": "Sie sind nicht berechtigt Personen zu diesem Verfahren hinzuzufügen",
          "notfound": "Die Person oder das Verfahren wurde nicht gefunden",
          "success": "Die Person wurde erfolgreich hinzugefügt"
        },
        "delete": {
          "badrequest": "Beim Löschen der Zugriffsberechtigung ist ein Fehler aufgetreten",
          "failed": "Das Entfernen der Zugriffsberechtigung ist fehlgeschlagen.",
          "notallowed": "Sie sind nicht berechtigt, den Zugriff für diese Person zu entfernen",
          "notfound": "Die angegebene Person konnte nicht als zugriffsberechtigte Person gefunden werden. Möglicherweise wurde sie bereits entfernt.",
          "success": "Zugriffsrechte wurden entfernt"
        },
        "register": {
          "success": "Die ausgewählte Person wurde erfolgreich zum Verfahren angemeldet."
        }
      },
      "optimize": {
        "failure": "Es konnte keine Lösung ermittelt werden. Evtl. sind die verfügbaren Plätze  nicht ausreichend.",
        "success": "Die Optimierung war erfolgreich."
      },
      "register": {
        "alreadyregistered": "Sie sind bereits für diese Veranstaltung angemeldet. Sie finden Ihre Anmeldung unter \"Meine Anmeldungen\".",
        "badrequest": "Ihre Anmeldung konnte nicht gespeichert werden. Bitte stellen Sie sicher, dass alle verpflichtenden Angaben vollständig sind.",
        "confirm": {
          "error": "Beim Bestätigen der Teilnahme ist ein Fehler aufgetreten. Bitte wenden Sie sich im Zweifel an den Support.",
          "limitreached": "Die maximale Anzahl Teilnahmebestätigungen in der dazugehörigen Gruppe wurde bereits erreicht. Eine weitere Bestätigung ist erst möglich, wenn eine Abmeldung von einer anderen Veranstaltung erfolgt ist.",
          "notallowed": "Sie sind nicht berechtigt, eine Bestätigung der Teilnahme durchzuführen.",
          "notfound": "Die angegebene Anmeldung wurde nicht gefunden.",
          "notpossible": "Eine Bestätigung der Anmeldung ist nicht möglich. Entweder befindet sich diese nicht im korrekten Status oder die Bestätigungsfrist ist abgelaufen.",
          "success": "Sie haben Ihre Teilnahme an der Veranstaltung erfolgreich bestätigt"
        },
        "edit": {
          "success": "Ihre Änderungen wurden erfolgreich gespeichert"
        },
        "error": "Es ist ein unbekannter Fehler aufgetreten. Bitte konktaktieren Sie ggf. den Support.",
        "manager": {
          "alreadyregistered": "Der ausgewählte Benutzer ist für dieses Verfahren bereits angemeldet"
        },
        "notallowed": "Eine Anmeldung zu dieser Veranstaltung ist für Sie leider nicht möglich.",
        "notfound": "Die Veranstaltung wurde nicht gefunden oder zwischenzeitlich gelöscht.",
        "notloggedin": "Sie sind nicht angemeldet. Um eine Veranstaltungsanmeldung durchzuführen müssen Sie sich mit Ihrem KIT-Account einloggen oder als externe Person registrieren und anmelden.",
        "remove": {
          "error": "Bei der Abmeldung ist ein allgemeiner Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
          "notallowed": "Sie sind nicht berechtigt, diese Anmeldung zu verwalten.",
          "notfound": "Die Anmeldung wurde nicht gefunden.",
          "success": "Sie wurden erfolgreich im System von der Veranstaltung abgemeldet."
        },
        "success": "Sie haben sich erfolgreich für die Veranstaltung angemeldet und Ihre Anmeldung befindet sich jetzt im Status \"Offen\".",
        "topicfull": "Das ausgewählte Thema/Termin/Veranstaltung ist bereits voll."
      },
      "registeredit": {
        "notallowed": "Ein Bearbeiten der Anmeldung ist nicht länger erlaubt. Entweder ist der Anmeldezeitraum abgelaufen oder die Anmeldung befindet sich in einem Zustand, welcher das Bearbeiten nicht zulässt."
      },
      "registrations": {
        "custommessage": {
          "error": "Beim Versenden der Nachricht ist ein Fehler aufgetreten",
          "success": "Ihre Nachricht wurde erfolgreich versendet"
        },
        "delete": {
          "error": "Beim Löschen der Bewerbungen ist ein Fehler aufgetreten",
          "success": "Es wurden alle Bewerbungen gelöscht."
        },
        "update": {
          "success": "Ihre Anmeldestatusänderungen wurden erfolgreich gespeichert."
        }
      },
      "renewaccessid": {
        "success": "Der private Zugriffslink wurde erneuert"
      },
      "save": {
        "error": "Beim Speichern ist ein Fehler aufgetreten.",
        "success": "Die Änderungen wurden gespeichert"
      },
      "topic": {
        "create": {
          "success": "Das Thema/der Termin {name} wurde erfolgreich angelegt"
        },
        "createassistant": {
          "success": "Die gewünschten Termine wurden erfolgreich angelegt"
        },
        "delete": {
          "confirm": "Möchten Sie die ausgewählten {count} Themen, Termine oder Veranstaltungen entfernen? Erst mit dem Speichern werden diese endgültig gelöscht.",
          "event": "Die Veranstaltung {name} wurde entfernt.",
          "notpossible": "Das Thema/der Termin <br/><strong>{name}</strong><br/> kann nicht gelöscht werden, da bereits Anmeldungen zugewiesen sind.<br/><br/> Bitte entfernen Sie zuerst die Zuweisungen.",
          "notpossibletitle": "Löschen nicht möglich",
          "singleappointment": "Der Einzeltermin {name} wurde entfernt.",
          "success": "Das Thema {name} wurde erfolgreich gelöscht. ",
          "topic": "Das Thema {name} wurde entfernt.",
          "weeklyappointment": "Der wöchentliche Termin {name} wurde entfernt."
        },
        "deletemark": {
          "event": "Der Veranstaltungseintrag {name} wurde zum Löschen markiert. Speichern Sie die Änderungen, um den Termin endgültig zu löschen.",
          "singleappointment": "Der Einzeltermin {name} wurde zum Löschen markiert. Speichern Sie die Änderungen, um den Termin endgültig zu löschen.",
          "topic": "Das Thema {name} wurde zum Löschen markiert. Speichern Sie die Änderungen, um den Termin endgültig zu löschen.",
          "weeklyappointment": "Der wöchentliche Termin {name} wurde zum Löschen markiert. Speichern Sie die Änderungen, um den Termin endgültig zu löschen."
        },
        "deletemarkremove": {
          "event": "Die Veranstaltungseintrag {name} ist nicht länger zum Löschen markiert.",
          "singleappointment": "Der Termin {name} ist nicht länger zum Löschen markiert.",
          "topic": "Das Thema {name} ist nicht länger zum Löschen markiert.",
          "weeklyappointment": "Der wöchentliche Termin {name} ist nicht länger zum Löschen markiert."
        },
        "update": {
          "success": "Das Thema/der Termin {name} wurde erfolglich aktualisiert"
        }
      }
    },
    "registration": {
      "error": "Beim Abrufen der Anmeldung ist ein allgemeiner Fehler aufgetreten. Bitte kontaktieren Sie bei Fragen den Support.",
      "notallowed": "Sie sind nicht berechtigt, auf diese Anmeldung zuzugreifen.",
      "notfound": "Die gewünschte Anmeldung konnte nicht gefunden werden. Möglicherweise wurde sie zwischenzeitlich gelöscht."
    },
    "template": {
      "convert": {
        "success": "Die Vorlage wurde erfolgreich erstellt"
      },
      "group": {
        "assign": {
          "success": "Die Gruppe wurden den ausgewählten Vorlagen zugewiesen"
        },
        "delete": {
          "success": "Die Gruppe {name} wurde gelöscht"
        },
        "remove": {
          "success": "Die Gruppenzuweisungen wurden entfernt"
        }
      }
    },
    "topic": {
      "error": {
        "fetch": "Beim Abruf der Themen und Termine ist ein Fehler aufgetreten."
      },
      "order": {
        "start": "Sortieren Sie die Themen über Drag&Drop"
      }
    }
  },
  "procedure": {
    "campuscompare": {
      "intro": "Diese Funktionalität erlaubt es Ihnen, die Anmeldeliste in diesem Anmeldeverfahren mit der Anmeldeliste einer zugriffsberechtigten Campus-Veranstaltung oder -Prüfung abzugleichen. Hierbei werden lediglich Differenzen angezeigt, es kann keine automatische Übertragung durchgeführt werden.",
      "registered": "Angemeldet",
      "showonlydifferent": "Nur abweichende Einträge anzeigen",
      "title": "Campus Anmeldelisten-Abgleich",
      "unregistered": "Abgemeldet"
    },
    "copy": {
      "accessrights": "Zugriffsrechte",
      "allsettings": "Alle Einstellungen",
      "groupassignment": "Gruppenzuordnung",
      "intro1": "Möchten Sie das Verfahren kopieren?",
      "intro2": "Folgende Inhalte werden dabei übernommen:",
      "intro3": "Folgende Inhalte werden NICHT übernommen:",
      "intro4": "Die Veranstaltung ist nach dem Duplizieren nicht automatisch sichtbar.",
      "linkedcampusevent": "Verknüpfte Campus-Veranstaltung (nur bei gleichem Semester)",
      "newtitle": "Neuer Titel",
      "ratings": "Abgebene Antworten oder Bewertungen",
      "requestedinformation": "Abgefragte Informationen",
      "title": "Verfahren kopieren",
      "topicsappointmentsevents": "Themen, Termine, Veranstaltungen"
    },
    "delete": {
      "buttonyes": "Ja, Löschen",
      "continuequestion": "Möchten Sie mit dem Löschen fortfahren?",
      "followingcontentalsodeleted": "Der folgende Inhalt wird ebenfalls gelöscht",
      "questions": "Fragen",
      "title": "{0} löschen?"
    },
    "edit": {
      "access": {
        "access": "Zugriffsberechtigung",
        "accesshint": "Legen Sie fest, wer auf Ihr Verfahren zugreifen darf.",
        "fullaccessbygroup": "Voller Zugriff über Gruppe",
        "readonlyall": "Lesender Zugriff (alles)",
        "readonlyownparticipants": "Lesender Zugriff (eigene Teilnehmer)",
        "searchpersontoadd": "Person zum Hinzufügen suchen",
        "visibility": "Sichtbarkeit",
        "visibilityhint": "Steuert die Sichtbarkeit Ihres Anmeldeverfahrens",
        "visibilitymissingmandatorywarning": "Es sind nicht alle Pflichtangaben ausgefüllt, daher kann die Sichtbarkeit des Verfahrens nicht auf Privat oder Öffentlich gestellt werden. Bitte prüfen Sie die Reiter mit dem roten Punkt.",
        "visibilitynonehint1": "Das Anmeldeverfahren ist für niemanden außer die zugriffsberechtigten Personen zugänglich und wird weder im Angebot noch im Vorlesungsverzeichnis aufgeführt",
        "visibilitynonehint2": "Dies ist der Standardwert nach dem Anlegen eines neuen Verfahrens, damit Sie in Ruhe alle notwendigen Einstellungen treffen können.",
        "visibilityprivatehint": "Private Verfahren werden nicht im Vorlesungsverzeichnis oder im Angebot gelistet. Der Zugriff ist über einen für das Anmeldeverfahren spezifischen Link (siehe Übersicht) möglich. Dieser Link kann an die gewünschten Teilnehmer z.B. per E-Mail weitergegeben werden.",
        "visibilitypublichint": "Das Verfahren ist öffentlich im Angebot und im Vorlesungsverzeichnis sichtbar.",
        "warningoptimizationwithouttopics": "Sie haben Optimale Verteilung als Vergabeverfahren angegeben, jedoch bisher keine Themen, Termine oder Veranstaltungen angelegt. Um eine versehentliche Freischaltung und daraus entstehende Probleme zu vermeiden, kann die Sichtbarkeit für Verfahren mit der optimalen Verteilung erst geändert werden, wenn auch Themen, Termine oder Veranstaltungen angelegt wurden."
      },
      "autoCcMail": "Auto E-Mail CC",
      "autoCcMailtooltip": "Wenn angegeben, wird die angegebene Adresse bei allen Mails, die manuell über die Teilnehmerliste versendet werden, als CC eingetragen.",
      "campuslistown": "Eigene Veranstaltungen",
      "campuslistrole": "Veranstaltungen Ihrer Rolle",
      "campuslistunit": "Veranstaltungen aus Ihrer OE",
      "caption": "Untertitel",
      "captiontooltip": "Der Untertitel erscheint im Angebot unterhalb der Veranstaltungsbezeichnung. Sie können hier wichtige Zusatzinformationen hinterlegen, z.B. Dozierende oder Termine.",
      "createnew": "Neues Verfahren anlegen",
      "createprocedure": "Verfahren anlegen",
      "createsuccesstext": "Ihr Verfahren wurde erfolgreich angelegt. Gehen Sie nun zu den Einstellungen, um ergänzende Angaben vorzunehmen. Reiter mit einem roten Punkt beinhalten noch auszufüllende Pflichtangaben.",
      "createtemplate": "Vorlage erstellen",
      "description": "Beschreibungstext",
      "descriptionhint": "Weitere Informationen zur Ihrer Veranstaltung. Wird in der Detailansicht und bei der Anmeldung angezeigt.",
      "eventreminder": "Veranstaltungserinnerung",
      "eventreminderhint": "Sie können einen Zeitpunkt für eine automatische Erinnerung an die Veranstaltung festlegen. Zu diesem Zeitpunkt erhalten alle Personen mit Zusage eine E-Mail mit den Terminen der verknüpften Campus-Veranstaltung. Sie können eigene Inhalte in diese aufnehmen.",
      "gotosettings": "Zu den Einstellungen",
      "lecturermode": {
        "full": "Voller Zugriff",
        "none": "Kein Zugriff",
        "readonly": "Lesender Zugriff"
      },
      "lecturermodehint": "Welchen Zugriff sollen an einer verknüpften Campus-Veranstaltung eingetragene Mitarbeitende auf dieses Verfahren erhalten? (gilt nur für neue Verfahren)",
      "lecturermodelabel": "Zugriffstufe für eingetragene Campus-Personen",
      "linkedcampusevent": "Verknüpfte Campus-Veranstaltung (optional)",
      "linkedcampuseventtooltip": "Eine verknüpfte Campus-Veranstaltung ermöglicht einen ILIAS-Teilnehmer-Export, die Anzeige von Informationen aus der Veranstaltung im Angebot sowie einen automatischen Hinweis zum Anmeldeverfahren im öffentlichen VVZ.",
      "missingmandatorytext": "Es fehlen Pflichtangaben, es wurde jedoch eine private oder öffentliche Sichtbarkeit definiert - Speichern nicht möglich.",
      "nametooltip": "Dies ist die Hauptbezeichnung Ihres Anmeldeverfahrens. Der Name erscheint im Angebot, in verschiedenen E-Mails sowie unter Meine Anmeldungen.",
      "nocampuseventsfound": "Keine zugriffsberechtigten Veranstaltungen gefunden",
      "notificationcontent": "Benachrichtigungsinhalte",
      "notificationcontentaccepted": "Benachrichtigung: Statuswechsel zu Zusage",
      "notificationcontentautodeclined": "Benachrichtigung: Statuswechsel zu Absage durch Automatismus",
      "notificationcontenteventreminder": "Zusätzliche Inhalte für Benachrichtigung: Veranstaltungserinnerung",
      "notificationcontenthint": "Sie können eigene Inhalte in die automatisch verschickten Status-E-Mails einfügen. Hinterlegen Sie einfach den Text (z.B. Zugangsdaten zu Zoom oder Informationen zum ersten Termin) in den entsprechenden Feldern.",
      "notificationcontentnewreg": "Benachrichtigung: Eingangsbestätigung der Anmeldung",
      "notificationcontentnocustomtext": "<ohne eigenen Inhalt>",
      "notificationcontentoffer": "Benachrichtigung: Statuswechsel zu Teilnahmeangebot",
      "notificationcontentwaitlist": "Benachrichtigung: Statuswechsel zu Warteliste",
      "notificationlanguage": "Benachrichtigungssprache",
      "notificationlanguagedescription": "Wenn sich Ihre Veranstaltung nur an eine Personengruppe mit gleicher Sprache wendet, können Sie hier die Sprache der Benachrichtigungen an die Teilnehmer umstellen (Standard: Zweisprachig)",
      "notifications": "Benachrichtigungen",
      "notificationshint": "Sie können automatische Benachrichtigungen bei Teilnehmeränderungen aktivieren.",
      "notificationsnewreg": "Benachrichtigung bei neuen Anmeldungen und Lerngruppenbeitritt",
      "notificationsnewregtooltip": "Alle am Verfahren eingetragene Personen erhalten eine E-Mail wenn eine neue Anmeldung eingeht.",
      "notificationsunreg": "Benachrichtigung bei Abmeldung oder automatischer Absage für Zusagen oder Angebote",
      "notificationsunregtooltip": "Wenn sich ein Teilnehmer im Status Zusage oder Angebot abmeldet oder die Teilnahmebestätigung nicht fristgerecht erfolgt, kann hier eine E-Mail-Benachrichtigung an alle eingetragenen Personen aktiviert werden.",
      "notificationsunregwaitlist": "Benachrichtigung bei Abmeldung von Warteliste",
      "notificationsunregwaitlisttooltip": "Versendet eine Benachrichtigung an alle Verwalter, wenn sich eine Person auf der Warteliste abmeldet.",
      "offeringplacement": "Einordnung im Angebot",
      "offeringplacementdescription": "Diese Angaben bestimmen, an welchen Stellen im Angebot Ihr Anmeldeverfahren angezeigt sind.",
      "primaryContactMail": "Kontakt E-Mail",
      "primarycontactmailtooltip": "Die E-Mail-Adresse wird als Absender für automatische E-Mails verwendet und als Kontaktadresse angezeigt.",
      "ratingtester": {
        "ratingnotvalid": "Bewertung nicht zulässig",
        "ratingvalid": "Bewertung zulässig",
        "title": "Testen Sie Ihre Bewertungskonfiguration"
      },
      "titleimage": "Titelbild",
      "titleimagechoose": "Bild-Datei auswählen",
      "titleimagechoosehint": "Es muss sich um eine Bild-Datei mit maximal 200kb Größe handeln",
      "titleimagehint": "Sie können ein Titelbild festlegen, welches in Ihren Angebotsdetails und bei der Anmeldung angezeigt wird.",
      "titleimageonleft": "Links",
      "titleimageontop": "Oben",
      "titleimageposition1": "Hier können Sie steuern, wo Ihr Titelbild in der Anmeldemaske und in der Vorschau angezeigt wird. Links positioniert das Bild klein neben dem Text (gut für Bilder im Posterformat), Oben blendet es in voller Breite oberhalb des Textes ein (gut für horizontale Bilder).",
      "titleimageposition2": "Die Bilder werden an den verfügbaren Platz geräteabhängig angepasst.",
      "titleimageremove": "Bild entfernen",
      "titleimageupload": "Titelbild hochladen"
    },
    "export": {
      "additionalsettings": "Zusätzliche Angaben",
      "excelfile": "Excel-Datei",
      "exportstructure": "Aufbau des Excel-Exports",
      "exportstructureallinone": "Alle Personen in einem Arbeitsblatt",
      "exportstructurefilepertopic": "Pro Thema/Termin/Veranstaltung eine Datei (ZIP-Archiv)",
      "exportstructurehoc": "HoC-Teilnehmerliste",
      "exportstructuresheetpertopic": "Pro Thema/Termin/Veranstaltung ein Arbeitsblatt",
      "iliasexportbutton": "ILIAS Export aufrufen",
      "iliasexportintro1": "Mit dem ILIAS Export können Sie Ihre Teilnehmerlisten in Ihre ILIAS Kurse exportieren. Dabei stehen bis zu drei verschiedene Möglichkeiten zur Verfügung, abhängig von der Konfiguration ihres Anmeldeverfahrens.",
      "iliasexportintro2": "Durch Klick auf den Button gelangen Sie zu einer Seite, welche die aktuellen Einstellungen prüft und Ihnen die möglichen Exportvarianten anzeigt.",
      "includekitaccount": "KIT Account (Kürzel)",
      "includekitcardnumber": "Nummer der KIT-Karte (nur für Zusagen und Studierende)",
      "includeplanned": "Geplante Status berücksichtigen",
      "includeuploadedfiles": "Hochgeladene Dateien (Weitere Angaben)",
      "includeuploadedfilestooltip": "Erzeugt immer eine ZIP Datei in der zusätzlich alle in den Antworten hochgeladene Dateien enthalten sind.",
      "intro": "Mit dieser Funktion können Sie sich eine Teilnehmerliste herunterladen. Wir bieten Ihnen die Möglichkeit, den Aufbau der Liste anzupassen, um damit optimal weiterarbeiten zu können.",
      "jsonfile": "JSON-Datei",
      "onlypublished": "Nur veröffentlichte Status verwenden",
      "plannedchanges": "Geplante Änderungen in der Teilnehmerliste",
      "statustoexport": "Zu exportierende Anmeldestatus",
      "title": "Excel Export"
    },
    "iliasexport": {
      "campusevent": "Campus-Veranstaltung",
      "exportoptions": "Exportmöglichkeiten",
      "exportpossibleas": "Export möglich nach",
      "followingparticipantscannotbetransferred": "Folge Personen können nicht übertragen werden:",
      "followingparticipantscannotbetransferredhint": "Die genannten Personen können nicht nach ILIAS exportiert werden, da sie in ILIAS nicht bekannt sind. Mitarbeiter/innen sowie Studierende müssen sich mindestens einmal in ILIAS angemeldet haben. Externe können derzeit nicht automatisch übertragen werden.",
      "groupincourse": "Gruppe im Kurs des Verfahrens",
      "missingcampusevent": "fehlende Campus-Veranstaltung",
      "missingiliascourse": "fehlender ILIAS-Kurs",
      "nocourseaccess": "Kein Zugriff auf Kurs",
      "participantswithacceptancefor": "Teilnehmer mit Zusage für",
      "procedureallparticipants": "Anmeldeverfahren (alle Teilnehmer)",
      "selectedforexport": "Für Export ausgewählt",
      "selectexportstyle": "Bitte wählen Sie aus, wie Sie die Teilnehmer exportieren möchten. Sie sehen oben in der Spalte \"Für Export ausgewählt\", welche Bestandteile dabei wie exportiert werden.",
      "stylenotavailableintro": "Dieser Modus steht Ihnen aktuell nicht zur Verfügung, da",
      "stylenotavailablenocampusevent": "keine Campus-Veranstaltung mit dem Anmeldeverfahren verknüpft ist. Somit kann kein ILIAS Kurs ermittelt werden.",
      "stylenotavailablenocourseaccess": "Sie keinen Zugriff auf den ILIAS Kurs der Campus-Veranstaltung besitzen.",
      "stylenotavailablenoeventtopics": "es keine Unter-Veranstaltungen mit einem ILIAS-Kurs gibt oder Sie keinen Zugriff auf diese Kurse besitzen.",
      "stylenotavailablenoiliascourse": "die Campus-Veranstaltung keinen ILIAS-Kurs besitzt.",
      "styleprimaryevent": "Haupt-Veranstaltung des Verfahrens",
      "styleprimaryeventandsubgroups": "Haupt-Veranstaltung sowie Unter-Veranstaltungen des Verfahrens",
      "styleprimaryeventandsubgroupshint": "Bei diesem Export werden für angelegte Unter-Veranstaltungen (Themen) die jeweiligen zugewiesenen Teilnehmer in den ILIAS-Kurs der Unterveranstaltung aufgenommen. So können Sie die Teilnehmer auch direkt in ILIAS auf die verschiedenen Kurse verteilen. Zusätzlich werden alle Teilnehmer in dem Kurs der mit dem Anmeldeverfahren verknüpften Veranstaltung eingetragen, so dass Sie neben den einzelnen Kursen auch einen gemeinsamen Kurs für alle Teilnehmer haben.",
      "styleprimaryeventhint": "In diesem Modus werden alle zugelassenen Personen als Mitglieder im ILIAS Kurs der mit dem Anmeldeverfahren verknüpften Veranstaltung exportiert.",
      "stylesubgroups": "Unter-Veranstaltungen des Verfahrens",
      "stylesubgroupshint": "Bei diesem Export werden für angelegte Unter-Veranstaltungen (Themen) die jeweiligen zugewiesenen Teilnehmer in den ILIAS-Kurs der Unterveranstaltung aufgenommen. So können Sie die Teilnehmer direkt auf die verschiedenen ILIAS-Kurse aufteilen.",
      "stylewithgroups": "mit Gruppen",
      "stylewithgroupshint": "In diesem Modus werden alle zugelassenen Personen als Mitglieder im ILIAS Kurs der mit dem Anmeldeverfahren verknüpften Veranstaltung exportiert. Zusätzlich wird für jedes Thema/Termin/Veranstaltung eine Gruppe im Kurs angelegt und Personen mit diesem zugewiesenen Thema der Gruppe hinzugefügt, so dass seperate Arbeitsbereiche innerhalb des Kurses bestehen. Sofern eine Person am Thema eingetragen sind, wird diese automatisch als Gruppenadministrator eingetragen."
    },
    "import": {
      "buttonimport": "Importieren",
      "choosefile": "Datei zum Import auswählen",
      "erroroccured": "Beim Verarbeiten der Datei wurde ein Problem festgestellt",
      "errorsoccured": "Es sind Fehler aufgetreten",
      "filehint": "Bitte eine Excel-Datei auswählen",
      "importfile": "Import-Datei",
      "intro": "Diese Funktion bietet Ihnen die Möglichkeit, die Anmeldeverfahren über eine Excel-Datei in das System zu importieren. Auf diese Weise können Sie viele Verfahren auf einen Schlag aktualisieren oder neu anlegen. Als Vorlage können Sie den Export unter Massenbearbeiten verwenden.",
      "line": "Zeile",
      "resultcreated": "Neu angelegt",
      "resultnochanges": "Keine Änderungen",
      "resultupdated": "Aktualisiert",
      "term": "SEMESTER",
      "title": "Anmeldeverfahren importieren"
    },
    "labels": {
      "access": "Zugriff und Sichtbarkeit",
      "additionalsettings": "Weitere Angaben",
      "basicsettings": "Grundeinstellungen",
      "currentlynooutstandingconfirmations": "Sie haben derzeit keine ausstehenden Teilnahmebestätigungen",
      "groupfilteractive": "Gruppenfilter aktiv",
      "registrationandassignment": "Anmeldung und Vergabe",
      "restrictionDegree": "Abschlüsse",
      "restrictionProducts": "Studiengänge",
      "restrictionUserType": "Persongruppen",
      "restrictions": "Einschränkungen",
      "showallprocedures": "Alle Verfahren anzeigen",
      "showonlyingroups": "Nur Verfahren folgender Gruppe anzeigen",
      "statsforselected": "Statistiken über ausgewählte Anmeldeverfahren",
      "topicsandappointments": "Themen und Termine"
    },
    "manage": {
      "additionalinfopopupplaceholder": "Frage/Bezeichnung (ESC zum Abbrechen)",
      "additionalinformation": "Abfrage weiterer Angaben",
      "additionalinformationclickonlabel": "Klicken um Bezeichnung/Fragetext einzugeben",
      "addquestion": "Angabe hinzufügen",
      "addtogroups": "Zu Gruppe hinzufügen",
      "allocationtypemandatorytext": "Wenn es sich um eine Pflichtveranstaltung mit weniger Plätzen als Anmeldungen handelt und zum Abbau des Überhangs keine weitere Veranstaltung angeboten werden kann, sind gemäß SPO die Plätze nach Studienfortschritt und unter Beachtung der Nachteilsausgleichssatzung zu vergeben. Dabei ist zu beachten, dass die Studierenden des Studiengangs Vorrang vor studiengangsfremden Studierenden haben. Unter den studiengangsinternen Studierenden darf nach durch Leistung(nicht bloß mit Fachsemestern) belegtem Studienfortschritt entschieden werden.Im Fall gleichen Studienfortschrittes sind weitere Auswahlkriterien festzulegen.Diese können sein: <ul><li>Bei gleichem Studienfortschritt nach Wartezeit</li><li>Bei gleicher Wartezeit durch Los</li><li>bei teilbaren Veranstaltungen nach Anfangsbuchstaben des Nachnamens</li></ul>",
      "allocationtypemandatorytitle": "Hinweis zu Pflichtveranstaltungen mit unzureichender Platzzahl",
      "allocationtypeusefulfor": "Besonders geeignet für",
      "answers": "Antworten",
      "availableplaces": "Verfügbare Plätze",
      "availableplaceshint": "Anzahl der Plätze im gesamten Anmeldeverfahren. 0 für unbegrenzt.",
      "availableplacestopichint": "Anzahl der Plätze für dieses Thema. 0 oder leer für unbegrenzt.",
      "confirmationdeadlinedays": "Bestätigungfrist in Tagen",
      "confirmationdeadlinedayshint": "Anzahl Tage nach einer Zusage in der die Bestätigung erfolgen muss",
      "confirmationdeadlinespecific": "Bestätigungsfrist absolut",
      "confirmationdeadlinespecifichint": "Falls die Bestätigungen bis zu einem festen Zeitpunkt erfolgen sollen, können Sie diesen hier auswählen.",
      "confirmationrequired": "Bestätigung erforderlich",
      "copygrouplink": "Link zum Angebot der Gruppe im ausgewählten Semester kopieren",
      "createprocedurefromtemplate": "Neues Verfahren aus Vorlage",
      "errors": {
        "closingdatemustbeafterend": "Die Nachrückfrist muss nach dem Anmeldeende liegen",
        "regendmustbeafterbegin": "Das Anmeldeende muss nach dem Anmeldebeginn liegen"
      },
      "events": "Ereignisse",
      "export": "Export",
      "exportexcel": "Export (Excel)",
      "exportwithcampuseventexcel": "Export inkl. aller Campus Veranstaltungen (Excel)",
      "fileuploadnote": "Hinweise zum Dateiupload",
      "fileuploadnotedeletion": "Hochgeladene Dateien werden automatisch nach 1 Jahr gelöscht.",
      "fileuploadnotemaxsize": "Die maximale Dateigröße liegt bei 5MB.",
      "fileuploadnotepdfonly": "Es können ausschließlich PDF-Dateien hochgeladen werden.",
      "hideendedprocedures": "Beendete Verfahren ausblenden",
      "importprocedures": "Import von Verfahren",
      "linkedcampusevent": "Zugehörige Campus Veranstaltung",
      "linkinoffer": "Link zum Angebot",
      "linkinoffering": "Link im Angebot",
      "lotterydate": "Los-/Rankingzeitpunkt",
      "massedit": "Massenbearbeitung",
      "mygroups": "Meine Gruppen",
      "myprocedures": "Meine Anmeldeverfahren",
      "mytemplates": "Meine Vorlagen",
      "newprocedure": "Neues Verfahren",
      "nogroups": "Sie haben bisher keine Gruppen.",
      "noprocedures": "In diesem Semester liegen noch keine Anmeldeverfahren für Sie vor.",
      "notemplates": "Sie haben bisher keine Vorlagen angelegt.",
      "openincampus": "In Campus öffnen",
      "openinstudyportal": "Im Studierendenportal öffnen",
      "overview": "Übersicht",
      "participantlistflat": "Gesamtteilnehmerliste",
      "participantlistzip": "Teilnehmer-Listen (ZIP)",
      "participantsearch": "Teilnehmersuche",
      "participantsearchhint": "Mit diesem Werkzeug können Sie für eine Person eine Liste der bestehenden Anmeldungen im ausgewählten Semester einsehen. Es werden nur Anmeldeverfahren angezeigt, auf welche Sie Zugriffsrechte besitzen.",
      "placesacc": "Plätzen",
      "placesandstudygroups": "Plätze und Lerngruppen",
      "privatelink": "Zugriffslink für private Verfahren",
      "privatelinkcaption": "Diese Adresse ist erst aktiv, wenn die Sichtbarkeit mindestens auf Privat gestellt ist.",
      "publiclink": "Öffentliche Addresse",
      "publiclinkcaption": "Diese Adresse ist erst aktiv, wenn die Sichtbarkeit auf Öffentlich steht.",
      "regenerate": "Neu generieren",
      "registrationdeadline": "Anmeldefrist",
      "removefromgroup": "Aus Gruppe ausfernen",
      "removefromgroups": "Aus Gruppen entfernen",
      "runallocationmanually": "Vergabeprozess manuell ausführen",
      "runallocationmanuallyintro1": "Möchten Sie für die folgenden Anmeldeverfahren den jeweiligen Vergabeprozess manuell ausführen?",
      "runallocationmanuallyintro2": "Es werden nur Anmeldeverfahren mit den Vergabearten \"First Come First Served\", \"Losverfahren\" sowie \"Fachsemester-Ranking\" unterstützt. Diese Aktion hat keinen Einfluss auf regulär stattfindende Vergabedurchläufe und wird nur dann ausgeführt, wenn das Nachrückende noch nicht erreicht ist.",
      "runallocationmanuallyonlywaitlist": "Nur Anmeldungen auf der Warteliste berücksichtigen",
      "searcherror": "Bei der Suche ist ein Fehler aufgetreten.",
      "selectedprocedures": "Ausgewählte Verfahren",
      "selectedtemplates": "Ausgewählte Vorlagen",
      "selectedtopics": "Ausgewählte Themen/Termine",
      "settings": {
        "registration": {
          "disablewaitlisttoggle": "Warteliste deaktivieren"
        },
        "restrictions": {
          "alloweddegrees": "Zulässige Abschlüsse",
          "allowedproducts": "Zulässige Studiengänge",
          "allowedproductsall": "Es sind alle Studiengänge zugelassen.",
          "allowedproductsconsecutive": "Anmeldung von angehenden Abschließern zu konsekutiven Studiengängen erlauben",
          "allowedproductsconsecutivetooltip": "Bei aktivierter Option ist eine Anmeldung für Studierende möglich, wenn diese kurz vor dem Abschluss stehen (z.B. 180 LP angemeldet im B.Sc. Architektur) und der konsekutive Studiengang zugelassen ist (z.B. M.Sc. Architektur), selbst wenn der aktuelle Studiengang selbst nicht zugelassen ist. Die soll die Anmeldung zu Master-Veranstaltungen von Bachelor-Studierenden erleichtern, die kurz vor dem Abschluss stehen.",
          "allowedproductsid": "Kennung",
          "allowedproductsselect": "Studiengänge auswählen",
          "allowedusertypes": "Zulässige Personengruppen",
          "brickrestrictions": "Voraussetzungen an Teilleistungen",
          "brickrestrictionsallpassed": "Alle Bedingungen müssen erfüllt werden",
          "brickrestrictionsnone": "Es sind keine Voraussetzungen an Teilleistungen definiert.",
          "brickrestrictionsonepassed": "Eine der Bedingungen muss erfüllt werden",
          "brickrestrictionstext": "Hier können Sie Bedingungen an Teilleistungen definieren, welche für die Anmeldung zu dieser Veranstaltung erfüllt sein müssen. Diese Voraussetzungen schränken die Personengruppen automatisch auf KIT-Studierende ein.",
          "brickrestrictionstypes": {
            "notpassed": "darf nicht bestanden sein",
            "notstarted": "darf nicht begonnen sein",
            "passed": "muss bestanden sein",
            "started": "muss begonnen sein"
          },
          "bricksearch": "Teilleistung suchen",
          "bricksearchnoresult": "Kein Suchergebnis oder Suchbegriff zu kurz (mind. 4 Zeichen)",
          "groupswithlimits": "Dieses Verfahren ist mit Gruppen verknüpft, welche zusätzlich die folgenden Anmeldebeschränkungen definieren",
          "intro": "Auf diesem Reiter können Sie Einschränkungen für die Anmeldung festlegen. Sofern Sie Einschränkungen definieren, können sich nur Personen zu dieser Veranstaltung anmelden, welche alle Voraussetzungen erfüllen.",
          "looserestrictions": "Einschränkungen lockern",
          "looserestrictionstext": "Wenn Sie diese Option aktivieren, ist eine Anmeldung trotz nicht erfüllter Einschränkungen möglich. Die Person erhält jedoch einen Warnhinweis.",
          "looserestrictionstoggle": "Anmeldung trotz Einschränkungen ermöglichen (mit Warnmeldung)",
          "noproceduresfound": "Keine zugriffsberechtigten Veranstaltungen gefunden",
          "procedures": {
            "label": "Einschränkungen bzgl. anderer Anmeldeverfahren",
            "none": "Es sind keine Einschränkungen zu anderen Anmeldeverfahren definiert.",
            "text": "Sie können die Anmeldung zu diesem Verfahren an den Anmeldezustand eines anderen Verfahrens koppeln.",
            "types": {
              "accepted": "muss eine Zusage haben",
              "notaccepted": "darf keine Zusage haben",
              "notregistered": "darf nicht angemeldet sein",
              "registered": "muss angemeldet sein"
            }
          },
          "procedureselect": "Anmeldeverfahren auswählen"
        },
        "topics": {
          "appointment": "Termin",
          "assistcount": "Anzahl",
          "assistduration": "Dauer",
          "assistfirstdate": "Erster Termin",
          "assistinminutes": "in Minuten",
          "assistintervall": "Intervall",
          "assistintro": "Mit diesem Assistenten können Sie mehrere aufeinanderfolgende Termine anlegen. Das Intervall ist hierbei die Zeit zwischen dem Start zweier Termine.",
          "assistintrohint": "Tipp: Um einen täglichen Termin zu erzeugen verwenden Sie als Intervall den Wert 1440 (=24h), für wöchentliche Termine den Wert 10080 (=1w).",
          "assistlist": "Basierend auf Ihren Angaben werden die folgende Termine erzeugt",
          "assistplaceshint": "Anzahl der Plätze für jeden dieser Termine. 0 oder leer für unbegrenzt.",
          "averagerating": "Ø-Bewertung",
          "averageratingrated": "Ø-Bewertung (nur bewertet)",
          "completerating": "Vollständige Bewertung",
          "completeratingcaption": "Müssen alle Themen oder Termine bewertet werden? Falls nicht, müssen nur die angegebenen Bewertungen erbracht werden.",
          "configtest": "Konfiguration testen",
          "currentallocationprocedure": "Ihr aktuelles Vergabeverfahren",
          "descriptionhint": "Hier können Sie einen zusätzlichen Beschreibungstext eintragen, der in den Details und bei der Anmeldung angezeigt wird.",
          "enablewaitlist": "Warteliste aktivieren",
          "enablewaitlistcaption": "Soll eine Anmeldung auf bereits volle Termine oder Themen möglich sein? In dem Fall landet die Anmeldung auf der Warteliste für diesen Termin/dieses Thema und rückt nach wenn Plätze frei werden. Bei deaktivierter Warteliste ist eine Anmeldung zu vollen Themen oder Terminen nicht möglich. (Standard: Nein)",
          "errors": {
            "invalidaverage": "Eine durchschnittlich erforderliche Bewertung muss zwischen 1 und 5 liegen.",
            "invalidsamepercentage": "Der max. Anteil gleicher Bewertungen muss zwischen 20% und 100% liegen.",
            "lowerlargerupperbound1": "Die untere Grenze für 1 Stern darf nicht größer als die obere Grenze sein.",
            "lowerlargerupperbound2": "Die untere Grenze für 2 Sterne darf nicht größer als die obere Grenze sein.",
            "lowerlargerupperbound3": "Die untere Grenze für 3 Sterne darf nicht größer als die obere Grenze sein.",
            "lowerlargerupperbound4": "Die untere Grenze für 4 Sterne darf nicht größer als die obere Grenze sein.",
            "lowerlargerupperbound5": "Die untere Grenze für 5 Sterne darf nicht größer als die obere Grenze sein.",
            "sumlargertopiccount": "Die Summe der mindestens abzugebenden Bewertungen darf nicht größer als die Anzahl der Themen/Termine sein.",
            "upperboundstoolowforcompleterating": "Die oberen Grenzen ermöglichen keine vollständige Bewertung."
          },
          "fcfstopicselection": "Bei First Come First Served mit Themen-/Terminwahl erfolgt eine Anmeldung zu einem auszuwählenden Thema oder Termin. Eine Anmeldung kann nur erfolgen, wenn in dem Thema oder Termin Plätze frei sind oder die Warteliste aktiviert ist.",
          "heatmapdialogintro": "In dieser Tabelle sehen Sie für alle Ihre Themen oder Termine eine Heatmap, deren Farben die Häufigkeit der jeweiligen Bewertung für das Thema darstellt. Je dunkler die Farbe, desto mehr Bewertungen haben sich auf diese Anzahl Sterne konzentriert. Die Heatmap erlaubt einen etwas detaillierteren Einblick in die Beliebtheit von Themen oder Terminen.",
          "heatmapdialogtitle": "Heatmap über Anzahl der jeweiligen Bewertungen",
          "intro": "Themen, Termine und Veranstaltungen können in den Anmeldeverfahren \"Optimierte Verteilung\" sowie \"First Come First Served\" genutzt werden, um eine Vorauswahl oder Bewertung während der Anmeldung zu aktivieren. Diese Bewertung oder Vorauswahl kann bei der Platzvergabe automatisch oder manuell berücksichtigt werden.<br /><br />Weitere Informationen finden Sie bei den",
          "label": "Themen / Termine",
          "mailtextaccepted": "Zusatztext Benachrichtigungsmail bei Zusage",
          "mailtextacceptedhint": "Wenn eine angemeldete Person eine Zusage mit diesem Thema oder Termin erhält, wird dieser Text in die Benachrichtigung aufgenommen. Kann zusammen mit dem allgemeinen Zusage-Text am Verfahren verwendet werden.",
          "maxsamepercentage": "Max. Anteil gleicher Bewertungen",
          "maxsamepercentagecaption": "in Prozent, z.B. 50%, leer für nicht genutzt",
          "noglobalplaces": "Sie haben für das Verfahren eine maximale Platzzahl vorgegeben. Beim Modus \"Auswahl eines Eintrags\" werden lediglich die Themen-spezifischen Platzangaben berücksichtigt, die globale Platzzahl findet keine Verwendung.",
          "norating": "berücksichtigt bei der Vergabe von Plätzen keine Bewertungen daher ist diese Optionen deaktiviert.",
          "noratingorselection": "berücksichtigt bei der Vergabe von Plätzen keine Bewertungen oder die Auswahl von Themen, daher sind diese Optionen deaktiviert.",
          "pastappointments": "Vergangene Termine zulassen",
          "pastappointmentscaption": "Sollen Termine, welche in der Vergangenheit liegen, ausgewählt werden können? (Standard: Nein)",
          "plausabilitycheck": "Plausibilitätsprüfung",
          "plausabilitycheckfailed": "Die angegebene Konfiguration ist durch Bewerber/innen nicht erfüllbar",
          "ratingcount": "Bewertungen",
          "requiredaveragerating": "Erforderliche durchschnittl. Bewertung",
          "requiredaverageratingcaption": "in Sternen (zwischen 1 und 5, z.B. 2,5, leer für nicht genutzt)",
          "requiredratings": "Anzahl abzugebender Bewertungen",
          "requiredratingscaption": "Sie können sowohl die minimal als auch die maximal abzugebenden Bewertungen eintragen. Möchten Sie explizite Bewertungen erzwingen, setzen Sie beide Werte auf die gleiche Zahl.",
          "sortmodetooltip": "Schaltet auf einen Modus um, in welchem die Einträge durch Drag&Drop umsortiert werden können.",
          "topicheatmap": "Bewertungen-Heatmap",
          "typenone": "Keine Bewertung oder Auswahl",
          "typenonehint": "Die angelegten Themen oder Termine spielen bei der Anmeldung keine Rolle. Sie können aber bei der Platzvergabe zugewiesen werden.",
          "typerating": "Bewertung mit Sternen",
          "typeratinghint": "Während der Anmeldung können bzw. müssen die Themen oder Termine mit 1-5 Sternen bewertet werden.",
          "typeselection": "Auswahl eines Eintrags",
          "typeselectionhint": "Die Anmeldung erfordert die Auswahl eines Themas oder Termins.",
          "unrated": "ohne Bew.",
          "usage": "Verwendung",
          "usageoptdist": "Im Vergabeverfahren \"Optimale Verteilung\" steht Ihnen nur die Bewertung mit Sternen zur Verfügung, da die Bewertung Grundlage der Verteilung darstellt.",
          "usagetext": "Sie haben die Wahl, ob Interessenten bei der Anmeldung direkt die angelegten Themen, Terminen oder Veranstaltungen bewerten oder aus diesen auswählen müssen."
        }
      },
      "showalladmin": "Alle Verfahren anzeigen (Admin)",
      "statistics": "Statistiken",
      "statsintro": "In diesem Semester gibt es ",
      "studygrouphint": "0 = keine Lerngruppen erlaubt",
      "studygroupmaxsize": "maximale Größe",
      "studygroupsize": "Lerngruppen-Größe",
      "successiondate": "Nachrückfrist",
      "termunpublished": "Das ausgewählte Semester ist nicht veröffentlicht. Anmeldeverfahren in diesem Semester sind im Angebot noch nicht sichtbar.",
      "texts": {
        "additionalinformation": "Wenn Sie bei der Anmeldung weitere Angaben abfragen möchten, können Sie diese hier festlegen.",
        "allocationmandatorynote": "Bitte beachten Sie: bei <strong>Pflichtveranstaltungen</strong> mit insgesamt weniger Plätzen als Anmeldungen sind die Plätze nach <strong>Studienfortschritt</strong> zu vergeben. Wählen Sie dazu die „Manuelle Auswahl“.",
        "allocationtype": "Angaben zur Verteilung der Plätze",
        "allocationtypepopup": "Sie können zwischen insgesamt 5 verschiedenen Platzvergabeverfahren auswählen. Klicken Sie unten das entsprechende Verfahren an um mehr Informationen zum jeweiligen Verfahren zu erhalten und sich für das passendste Verfahren für Ihren Anwendungsfall zu entscheiden.",
        "configuretopicsnote": "Sie können die Themen oder Termine, für welche Sie eine Verteilung durchführen möchten, im Reiter \"Themen und Termine\" konfigurieren.",
        "confirmation": "Soll eine Bestätigung der Teilnahme erforderlich sein? Erfolgt eine Bestätigung nicht rechtzeitig, erhält der Teilnehmer automatisch eine Absage und der Platz wird frei.",
        "disablewaitlist": "Über diese Option können Sie die Warteliste im Vergabeverfahren deaktivieren. ",
        "disablewaitlistwarn": "Bitte beachten Sie: Beim Aktivieren dieser Option erhalten automatisch alle bestehenden Anmeldungen im Status \"Warteliste\" eine Absage.",
        "registrationperiodnote": "Neuerdings können Sie den Anmeldebeginn und -ende auch leer lassen. Es handelt sich dann um eine dauergeöffnete Veranstaltung."
      },
      "timeline": "Zeitlicher Ablauf",
      "topicsappointments": "Themen/Termine",
      "totemplate": {
        "intro1": "Möchten Sie aus dem ausgewählten Verfahren eine neue Vorlage erstellen?",
        "linkedcampusevent": "Verknüpfte Campus-Veranstaltung",
        "templatename": "Name der Vorlage",
        "title": "Vorlage aus Verfahren erstellen",
        "topicevents": "Unter-Veranstaltungen"
      },
      "triggermanualallocation": "Vergabeverfahren manuell ausführen",
      "unpublishedprocedures": "Sie haben unveröffentlichte Anmeldeverfahren. Diese sind im Angebot noch nicht sichtbar. Sie können die Sichtbarkeit im Reiter \"Zugriff\" der Einstellungen des Anmeldeverfahrens steuern.",
      "usage": "Auslastung"
    },
    "newsettingsadditionalinfo": "Weitere Informationen zu den Einstellungen",
    "newsettingsintro": "Wir haben die Einstellungen überarbeitet. Themen/Termine und Zugriff finden Sie nun im Reiter Einstellungen.",
    "registrations": {
      "addnewdialog": {
        "findperson": "Person suchen",
        "intro": "Mit dieser Funktion können Sie eine Anmeldung für eine andere Person vornehmen, welche sich aufgrund von Beschränkungen oder Anmeldezeitraum nicht selbst anmelden kann. Die Abgabe von Bewertungen oder zusätzlichen Angaben ist auf diesem Wege jedoch nicht möglich.",
        "personnotifiedtext": "Die Person wird automatisch über ihre Anmeldung informiert.",
        "title": "Neue Anmeldung hinzufügen"
      },
      "addregistration": "Anmeldung hinzufügen",
      "appointmentconflict": "Terminkonflikt mit anderer Veranstaltung",
      "boostedregistration": "Geboostete Anmeldung",
      "bydefaultweighting": "nach Standard-Gewichtung",
      "campuscompare": "Campus Teilnehmer-Vergleich",
      "choosetopic": "Thema wählen",
      "declineopen": "Offenen Bewerbungen absagen",
      "filterbyperson": "Nach Personen filtern",
      "labels": {
        "createdon": "Eingang",
        "overbooking": "Überbuchung",
        "points": "Punkte",
        "registrationlist": "Anmeldeliste",
        "regstatus": "Anmeldestatus",
        "topicapp": "Thema/Termin",
        "topicplaces": "Themenplätze",
        "waitlistplace": "Wartelistenplatz"
      },
      "optimizationallowedassignments": "Erlaubte Zuweisungen",
      "optimizationarchive": "Archivieren",
      "optimizationarchived": "Archivierte Optimierungen",
      "optimizationassignedplaces": "Vergebene Plätze",
      "optimizationassignedratings": "Zugewiesene Bewertungen",
      "optimizationcontinueintro": "Sie setzen eine bereits begonnene Verteilung fort. Diese Funktion dient dazu noch offen gebliebene Anmeldungen auf freie oder frei gewordene Plätze optimal zu verteilen. Es werden nur freie Plätze sowie offene Anmeldungen verwendet. Sie können auch die verfügbare Platzzahl bei den einzelnen Terminen erhöhen um mehr Platz zu schaffen.",
      "optimizationdeletearchived": "Archivierten Eintrag löschen",
      "optimizationguaranteedplaces": "Garantierte Teilnahme",
      "optimizationguaranteedplacestooltip": "Hier können Sie steuern, ob jede angemeldete Person einen Platz zugewiesen erhalten soll. In diesem Fall müssen ausreichend Plätze vorhanden sein. Deaktiveren Sie diese Option werden bei Platzmangel einige Teilnehmer eine Absage erhalten.",
      "optimizationminpersonspertopic": "Mind. Teilnehmer pro Thema/Termin",
      "optimizationnoplace": "Kein Platz erhalten (=Absage)",
      "optimizationnoplaceorconflict": "Kein Platz erhalten oder Terminkonflikt",
      "optimizationonestarassignment": "1 Sterne Zuweisungen",
      "optimizationoptimize": "Optimieren",
      "optimizationparameters": "Optimierungsparameter",
      "optimizationplaces": "Plätze",
      "optimizationrelative0": "Höchste Bewertung erhalten",
      "optimizationrelative1": "Leichte Abweichung von höchster Bewertung (1 Stern weniger)",
      "optimizationrelative2": "Mittlere Abweichung von höchster Bewertung (2 Sterne weniger)",
      "optimizationrelative3": "Große Abweichung von höchster Bewertung (3 Sterne weniger)",
      "optimizationrelative4": "Sehr große Abweichung von höchster Bewertung (4 Sterne weniger)",
      "optimizationrelativehappiness": "Relative Zufriedenheit",
      "optimizationresetplaces": "Platzanzahl zurücksetzen",
      "optimizationrestoreconfig": "Konfiguration und Zuweisungen wiederherstellen",
      "optimizationresult": "Optimierungsergebnis",
      "optimizationsetequalplaces": "Platzanzahl auf Gleichverteilung setzen",
      "optimizationsetequalplacesdialogmessage": "Möchten Sie die am Thema oder Termin gespeicherte Platzanzahl mit der passenden Platzahl für eine gleichmäßige Verteilung überschreiben? Hierbei wird die Platzanzahl auf Aufrunden(Anzahl Teilnehmer / Anzahl Themen/Termine) gesetzt.",
      "optimizationsetequalplacesdialogtitle": "Gleiche Platzanzahl für alle Themen/Termine setzen",
      "optimizationtopicisunrated": "Es wurde keine Bewertung für den zugewiesenen Termin abgegeben.",
      "optimizationtopics": "Themen",
      "optimizationunratedtopics": "unbewertete Themen",
      "optimizationwarninginsufficientplaces": "Es existieren nicht ausreichend Plätze für alle Teilnehmer. Bitte erhöhen Sie die Platzzahl der Themen bzw. Termine oder deaktivieren Sie die garantierte Teilnahme. In diesem Fall erhalten nicht alle Anmeldungen eine Zusage.",
      "optimizationweighting": "Gewichtung",
      "optimizationweightingtooltip": "Über diese Einstellung regeln Sie, wie sich die Bewertungen im Optimierungsalgorithmus auswirken. In der linearen Skala werden alle Zuweisungen gleich bewertet (d.h. eine Verbesserung von 1 auf 2 Sterne ist gleich viel Wert wie eine Verbesserung von 4 auf 5 Sterne). In der Standardskala werden Verbesserungen bei niedrigen Bewertungen stärker bewertet (d.h. das System bewertet eine 2-Sterne-Zuweisung als Alternative zu einer 1-Stern-Zuweisung positiver als eine Verbesserung von 4 auf 5 Sterne)",
      "plannedchanges1": "Sie haben geplante Änderungen an",
      "plannedchanges2": ", welche automatisch zwischengespeichert wurden. Die betroffenen Einträge sind rot und mit Sternchen markiert und die vorherigen Werte mit einem blauen Rahmen versehen.",
      "plannedchanges3": "Änderungen können verworfen oder veröffentlicht werden und gehen durch einen Wechsel der Seite oder Logout nicht verloren.",
      "registrationmerge": {
        "intro": "Möchten Sie die ausgewählten {0} Anmeldungen zu einer Anmeldung zusammenführen? Sie bilden damit eine Lerngruppe. Bitte wählen die Anmeldung aus, welche der Lerngruppenleiter wird. Alle anderen Anmeldungen werden dabei zu dieser hinzugefügt. Bitte beachten Sie, dass dabei Informationen wie weitere Angaben und Bewertungen der ursprünglichen Anmeldungen verloren gehen.",
        "title": "Anmeldungen zusammenführen"
      },
      "selectedtopicorappointment": "Bei der Anmeldung gewähltes Thema oder Termin",
      "showstudygroupnames": "Namen der Lerngruppenmitglieder anzeigen",
      "splitfromregistration": {
        "text": "Möchten Sie {0} aus dieser Lerngruppe entfernen? Für die Person wird eine neue Einzelanmeldung angelegt, welche die Bewertungen, aber nicht die Angaben aus der Lerngruppe übernimmt. ",
        "title": "Person aus Lerngruppe ausgliedern"
      },
      "stickyview": "Tabellenkopf anheften",
      "texts": {
        "declineopentextcount": "Sie haben <strong>{0} Bewerbungen</strong> im Status Offen.<br /><br />Möchten Sie diese auf abgelehnt setzen? Die Änderungen werden erst wirksam, wenn Sie diese im Anschluss über Anwenden veröffentlichen.",
        "discardchangestext": "Sie haben Änderungen vorgenommen, die nicht gespeichert wurden. Wollen Sie diese verwerfen?",
        "discardchangestextcount": "Sie haben <strong>{0} unveröffentlichte Änderungen</strong>.<br />Möchten Sie diese wirklich verwerfen? Dabei werden alle Änderungen auf den derzeit veröffentlichten Stand zurückgesetzt.",
        "discardchangestitle": "Änderungen verwerfen?",
        "publishchangestitle": "Änderungen veröffentlichen",
        "savechangesall": "Alle ausstehenden Änderungen ({0})",
        "savechangesselected": "Nur die {0} ausgewählten Einträge",
        "savechangestext": "Möchten Sie die ausstehenden Änderungen veröffentlichen?",
        "savechangestextcount": "Sie haben <strong>{0} ausstehende Änderungen</strong>.<br />Möchten Sie diese nun veröffentlichen?<br /><br /><em>Die betroffenen Personen werden über ihre neue Status-Zuweisung per E-Mail informiert.</em>",
        "savechangestitle": "Änderungen speichern"
      },
      "viewbuttons": "Button-Darstellung",
      "viewextcharts": "Erweiterte Belegungsanzeige",
      "viewextended": "Erweiterte Angaben"
    },
    "sendmessage": {
      "allregistrationsinstate": "Alle Anmeldungen im Status ...",
      "hint1": "Ihre Nachricht wird an",
      "hint2": "versendet. Sie erhalten automatisch eine Kopie.",
      "individualregistrations": "Individuelle Anmeldungen"
    },
    "showoncampusplan": "Anzeige auf dem Campusplan"
  },
  "procedureview": {
    "availableplaces": "Verfügbare Plätze ",
    "conflict": "Terminkonflikt",
    "contact": "Kontakt-Adresse zu dieser Veranstaltung",
    "contactdesc": "Bei Fragen zu dieser Veranstaltung oder zur Anmeldung wenden Sie sich bitte an die o.g. E-Mail-Adresse.",
    "contentratings": "Der Anbieter hat folgende Angaben zu Themenschwerpunkten gemacht:",
    "disablewaitlist": "Warteliste deaktivieren",
    "groupintro": "Diese Veranstaltung ist Teil der Gruppe",
    "grouplimitboosttext": "In dieser Gruppe können Boosts vergeben werden, um die Gewichtung der Anmeldung zu erhöhen",
    "grouplimitconfirmationtext": "Innerhalb dieser Gruppe ist die Anzahl der möglichen Teilnahmebestätigungen pro Semester begrenzt",
    "grouplimitother": "Alle nicht explizit aufgeführten Studiengänge",
    "grouplimits": {
      "boostsingular": "Boost",
      "boostsplural": "Boosts",
      "confirmationplural": "Teilnahmebestätigungen",
      "confirmationsingular": "Teilnahmebestätigung",
      "noboosts": "Keine Boosts erlaubt",
      "noconfirmation": "Keine Teilnahmebestätigung möglich",
      "nolimit": "keine Beschränkung",
      "noregistration": "keine Anmeldung möglich",
      "registrationplural": "Anmeldungen",
      "registrationpluralsolo": "Anmeldungen",
      "registrationsingular": "Anmeldung"
    },
    "grouplimittext": "Innerhalb dieser Gruppe ist die Anzahl der möglichen Anmeldungen pro Semester begrenzt",
    "groupshortname": "Kürzel",
    "headings": {
      "additionalInformation": "Weitere Informationen",
      "group": "Gruppe",
      "registrationandallocation": "Anmeldung und Platzvergabe",
      "requiredinformation": "Angaben während der Anmeldung",
      "restrictions": "Beschränkungen",
      "studygroup": "Lerngruppe",
      "topicsappointmentsevents": "Themen, Termine und Veranstaltungen",
      "topicselection": "Ausgewähltes Thema/Termin/Veranstaltung"
    },
    "linkedcampusevent": "Zugehörige Campus-Veranstaltung",
    "note": "Inhalt",
    "notloggedinwarning": "Sie sind nicht angemeldet. Bitte loggen Sie sich oben rechts ein, um sich für diese Veranstaltung anzumelden.",
    "registrationlimited": "Die Anmeldung zu dieser Veranstaltung ist beschränkt auf:",
    "registrationperiod": "Anmeldezeitraum",
    "requiredinformation": "Während der Anmeldung müssen Sie die folgenden Angaben machen",
    "studygroupfounder": "Gründer",
    "studygroupintro": "Diese Veranstaltung erlaubt Lerngruppen mit bis zu {0} Mitgliedern. Alle Mitglieder erhalten den gleichen Status sowie die gleiche Themen-, Termin- bzw Veranstaltungszuweisung.",
    "studygrouplink": "Beitrittslink zu Ihrer Lerngruppe",
    "studygrouplinkintro": "Sie können weitere Personen zu Ihrer Lerngruppe einladen, indem Sie den folgenden Link weitergeben",
    "studygroupmemberlist": "Derzeit besitzt diese Anmeldung die folgenden Mitglieder",
    "studygroupsizedesc": "bis zu {0} Mitglieder",
    "taepresent": "Zu dieser Veranstaltungen wurden folgende Themen, Termine oder Veranstaltungen eingetragen, welche Sie zusammen mit einer Zusage zugewiesen bekommen können.",
    "taeratingrequired": "Während der Anmeldung müssen Sie für diese eine Bewertung abgeben.",
    "yourrating": "Ihre Bewertung",
    "yourregstatus": "Ihr Anmeldestatus",
    "yourresponse": "Ihre Antwort",
    "yourresponses": "Ihre Antworten",
    "yourtopic": "Ihr Thema/Termin/Veranstaltung",
    "yourtopicassignment": "Sie haben dieses Thema/diesen Termin/diese Veranstaltung erhalten!",
    "yourtopicselection": "Sie haben dieses Thema/diesen Termin/diese Veranstaltung ausgewählt.",
    "yourupload": "Ihr hochgeladenes Dokument"
  },
  "registration": {
    "add": {
      "backtooffer": "Zurück zum Angebot",
      "backtooffering": "Zurück zum Angebot",
      "degreesallowed": "Zugelassene Abschlüsse",
      "degreesyou": "Ihr ausgewählter Abschluss",
      "enableautoupdate": "Platzaktualisierung aktivieren",
      "error": {
        "heading": "Anmeldung nicht möglich",
        "intro": "Leider ist eine Anmeldung für Sie nicht möglich:",
        "productnoreg": "Für Ihren Studiengang ist keine Anmeldung möglich.",
        "regconditional": "Anmeldung nur unter Vorbehalt möglich",
        "regconditionaltext": "Eine Anmeldung zu dieser Veranstaltung ist für Sie aus den unten genannten Gründen nur unter Vorbehalt möglich. Der Veranstalter hat zugelassen, dass eine Anmeldung auch bei Nichterfüllung der Voraussetzungen möglich ist, eine Teilnahme kann jedoch nachträglich ausgeschlossen werden. Bitte stellen Sie sicher, dass Sie an der Veranstaltung teilnehmen dürfen."
      },
      "event": "Veranstaltung",
      "intro": "Sie haben sich erfolgreich angemeldet.",
      "newregistration": "Neue Anmeldung",
      "noautoupdate": "Sie waren zu lange inaktiv. Um die automatische Aktualisierung der verfügbaren Plätze wieder zu aktivieren, klicken Sie bitte hier",
      "nofreeplaces": "Keine freien Plätze",
      "notice": "Bitte beachten Sie, dass eine Anmeldung keinen Anspruch auf Teilnahme darstellt.",
      "notificationnotice": "Sie erhalten vom System eine automatische Anmeldebestätigung mit dem aktuellen Status sowie weitere E-Mails bei Statusänderungen. Den aktuellen Status finden Sie jederzeit unter \"Meine Anmeldungen\".",
      "productsallowed": "Zugelassene Studiengänge",
      "productsyou": "Ihr ausgewählter Studiengang",
      "registernow": "Jetzt anmelden",
      "regsperterm": "Anmeldungen pro Semester",
      "title": "Anmeldung erfolgreich",
      "usergroupallowed": "Zugelassene Personengruppen",
      "usergroupyou": "Ihre Personengruppe"
    },
    "buttons": {
      "edit": "Bearbeiten",
      "registrationadd": "Neue Anmeldung",
      "registrationedit": "Anmeldung bearbeiten",
      "registrationgoto": "Zur Anmeldung",
      "registrationview": "Anmeldung ansehen",
      "view": "Ansehen"
    },
    "errors": {
      "alreadyended": "Der Anmeldezeitraum für die Veranstaltung ist abgelaufen.",
      "alreadyregistered": "Sie sind für diese Veranstaltung bereits angemeldet.",
      "bricknotpassed": "Um sich für diese Veranstaltung anmelden zu können, darf die folgende Teilleistung nicht bestanden sein: ",
      "bricknotstarted": "Um sich für diese Veranstaltung anmelden zu können, darf die folgende Teilleistung noch nicht begonnen sein: ",
      "brickpassed": "Eine Anmeldung ist nur möglich, wenn die folgende Teilleistung bereits bestanden wurde: ",
      "brickstarted": "Eine Anmeldung ist nur möglich, wenn die folgende Teilleistung bereits begonnen wurde: ",
      "cannotjoinfullprocedure": "Ein Beitritt zu einer Anmeldung in einem Zusage-Status ist nur möglich, wenn in der Veranstaltung noch freie Plätze existieren. Sie können sich eigenständig anmelden und in der Warteliste auf einen verfügbaren Platz warten.",
      "cannotjoinfulltopic": "Ein Beitritt zu einer Anmeldung in einem Zusage-Status ist nur möglich, wenn in dem gewählten Thema, Termin oder Veranstaltung noch freie Plätze existieren. Sie können sich eigenständig anmelden und in der Warteliste auf einen verfügbaren Platz warten.",
      "grouplimitreached": "Sie haben die maximal zulässige Anzahl an Anmeldungen in einer der zugewiesenen Veranstaltungsgruppen erreicht.",
      "invaliddegree": "Die Anmeldung ist nur für einige Abschlüsse zulässig. Sie besitzen derzeit keinen Studiengang in einem der zugelassenen Abschlüsse.",
      "invalidproduct": "Die Anmeldung ist nur für spezifische Studiengänge zulässig. Derzeit sind Sie in keinem der zulässigen Studiengänge immatrikuliert.",
      "invalidstate": "Die Anmeldung befindet sich in einem Status, der keinen weiteren Beitritt zur Lerngruppe erlaubt.",
      "invalidusertype": "Sie gehören nicht zu den für diese Veranstaltung zugelassenen Personengruppen.",
      "notactive": "Der Anmeldezeitraum ({0 }- {1}) ist nicht aktiv.",
      "notstarted": "Der Anmeldezeitraum hat noch nicht begonnen.",
      "placesfull": "Die verfügbaren Plätze für diese Veranstaltung sind bereits belegt und es existiert keine Warteliste.",
      "restrictionaccepted": "Die Anmeldung erfordert, dass Sie für die folgende Veranstaltung bereits eine Zusage erhalten haben: ",
      "restrictionnotaccepted": "Um sich für diese Veranstaltung anmelden zu können, dürfen Sie nicht bereits für folgende Veranstaltung eine Zusage erhalten haben: ",
      "restrictionnotregistered": "Um sich für diese Veranstaltung anmelden zu können, dürfen Sie nicht bereits für folgende Veranstaltung angemeldet sein: ",
      "restrictionregistered": "Die Anmeldung erfordert, dass Sie für die folgende Veranstaltung bereits angemeldet sind: ",
      "studygroupfull": "Die Lerngruppe hat die maximale Teilnehmerzahl erreicht."
    },
    "headings": {
      "masterdata": "Stammdaten",
      "ratings": "Bewertungen",
      "requestedinformation": "Weitere Angaben",
      "topicselection": "Auswahl von Themen, Terminen oder Veranstaltungen"
    },
    "join": {
      "error": {
        "intro": "Leider ist ein Beitritt zur angegebenen Lerngruppe für Sie nicht möglich",
        "title": "Beitritt nicht möglich"
      },
      "intro": "Sie sind im Begriff einer Lerngruppe für die o.g. Veranstaltung beizutreten.<br /> Mitglieder einer Lerngruppe erhalten immer den gleichen Status (Warteliste/Zusage/Absage) und ggf. die gleiche Themen- oder Termin-Zuweisung.<br /> Diese Lerngruppe wurde von <strong>{0}</strong> erstellt.",
      "joinnow": "Jetzt beitreten",
      "joinregistration": "Anmeldung beitreten",
      "ratingintro": "Um sich anzumelden müssen Sie die folgenden Themen, Termine bzw. Veranstaltungen mit 1-5 Sternen bewertet werden. Die unten stehende Bewertung wurde durch den Gründer der Lerngruppe vorgegeben und gilt für alle Teilnehmer",
      "state": "Die Anmeldung befindet sich aktuell im Status: "
    },
    "labels": {
      "gotooffer": "Zum Angebot",
      "gotooffering": "Zum Angebot",
      "mandatory": "Pflichtangabe",
      "namematno": "Name und Matrikelnummer",
      "optional": "Optionale Angabe",
      "productterm": "Studiengang und Fachsemester (im Veranstaltungssemester)",
      "usergroup": "Personengruppe"
    },
    "texts": {
      "assigned": "vergeben",
      "masterdataintro": "Die folgenden Angaben werden dem Veranstalter automatisch bereitgestellt. Mit der Anmeldung erklären Sie sich damit einverstanden, dass diese Daten zum Zweck der Anmeldung gespeichert und an den Veranstalter weitergegeben werden.",
      "noowner": "Kein Besitzer",
      "ratingcountsintro": "Sie müssen mindestens die folgenden Bewertungen abgeben",
      "ratingcountsmax": "max. {0}x (derzeit: {1})",
      "ratingcountsmin": "min. {0}x (noch {1})",
      "ratingcountsminmax": "{0}-{1}x (derzeit: {2})",
      "ratingcountsminmaxsame": "genau {0}x (derzeit: {1})",
      "ratingcountsminok": "min. {0}x",
      "ratingcountsnone": "keine Bewertung zulässig (derzeit: {0})",
      "ratingcountsoverflow": "Bewertungen über die geforderten Grenzen hinaus werden für das Erfüllen niedrigerer Anforderungen verwendet.",
      "ratingcountsremaining": "noch {0}",
      "ratingincomplete": "Ihre Anmeldung erfüllt noch nicht alle Anforderungen an die Bewertung.",
      "ratingintro": "Um sich anzumelden müssen Sie die folgenden Themen, Termine bzw. Veranstaltungen mit 1-5 Sternen bewerten.",
      "ratingrequireallrated": "Es müssen alle Themen bewertet werden",
      "ratingrequireaverage": "Durchschnittsbewertung <strong>mind. {0} Sterne</strong> (derzeit: {1})",
      "ratingrequireequalpct": "<strong>Maximal {0}%</strong> der Themen dürfen gleich bewertet werden (derzeit: {1}%)",
      "ratingrequirementintro": "Die abzugebenden Bewertungen müssen folgende Anforderungen erfüllen",
      "requestedinformationincomplete": "Es wurden noch nicht alle Pflichtangaben ausgefüllt.",
      "selectionincomplete": "Es muss ein Thema, Termin bzw. eine Veranstaltung ausgewählt werden.",
      "selectionintro": "Für die Anmeldung müssen Sie aus der unten stehenden Liste genau ein Thema bzw. einen Termin oder eine Veranstaltung auswählen, an welchem bzw. welcher Sie interessiert sind. Bei der Platzvergabe wird anhand Ihrer Auswahl eine Entscheidung über ein Platzangebot bzw. eine Zusage getroffen.",
      "selectionwaitlist": "Die Warteliste für bereits gefüllte Themen oder Termine ist aktiviert. Sie können sich auf ein Thema, Termin oder eine Veranstaltung anmelden, auf welchem/r bereits alle Plätze vergeben sind und erhalten eine Zusage, wenn dort ein Platz frei wird. Sie sehen die gesamte Anzahl angemeldeter Personen (inkl. bisheriger Warteliste).",
      "studygroupmemberintro": "Sie sind Mitglied einer Lerngruppe für diese Anmeldung. Die Anmeldung kann nur durch den Gründer der Lerngruppe ({0}) bearbeitet werden."
    }
  },
  "rms": {
  },
  "stats": {
    "titles": {
      "deadlineCounts": "Aktuell ausstehende Bestätigungsfristen",
      "degreeCounts": "Verteilung der Anmeldungen auf Abschlüsse",
      "productCounts": "Verteilung der Anmeldungen auf Studiengänge",
      "registrationTimeCounts": "Eingang der Anmeldungen",
      "stateCounts": "Verteilung des Anmeldestatus ",
      "topProcedures": "Anmeldeverfahren mit den meisten Anmeldungen"
    }
  },
  "texts": {
    "createnewprocedure": "Neues Anmeldeverfahren anlegen",
    "createnewtemplate": "Neue Vorlage anlegen",
    "editprocedure": "Einstellungen",
    "exportilias": {
      "noAccess": "Sie sind nicht berechtigt auf den ILIAS Kurs zuzugreifen",
      "noCampusEvent": "Es ist keine Campus-Veranstaltung hinterlegt",
      "noIliasCourse": "Es gibt keinen ILIAS Kurs zur angegeben Campus-Veranstaltung"
    },
    "group": {
      "create": "Gruppe anlegen"
    },
    "groups": {
      "access": "Gruppenzugriff verwalten",
      "edit": "Gruppe bearbeiten"
    },
    "personselectstudenthint": "Sie können Studierende über deren vollständige Matrikelnummer oder ihren u-Account auswählen",
    "perterm": "pro Semester",
    "topics": {
      "assistant": "Termin-Assistent",
      "editevent": "Veranstaltung bearbeiten",
      "editsingleappointment": "Einzel-Termin bearbeiten",
      "edittopic": "Thema bearbeiten",
      "editweeklyappointment": "Wöchentl. Termin bearbeiten",
      "newevent": "Veranstaltung hinzufügen",
      "newsingleappointment": "Neuen Einzel-Termin anlegen",
      "newtopic": "Neues Thema anlegen",
      "newweeklyappointment": "Neuer wöchentl. Termin"
    }
  }
}
