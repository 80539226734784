{
  "abbreviations": {
    "fs": "Term"
  },
  "degrees": {
    "11": "Diploma",
    "38": "LA Bachelor",
    "47": "LA Bachelor",
    "64": "LA Master",
    "66": "LA Master",
    "82": "Bachelor",
    "88": "Master",
    "97": "Temporary Studies",
    "ISL": "ISL Test",
    "TEST": "ISL Test"
  },
  "errors": {
    "invalidMail": "The mail address is invalid",
    "invalidPasswordComplexity": "The password needs to at least 6 characters and include at least a number, an uppercase and an lowercase letter.",
    "nonKitOnly": "Registration with KIT addresses is not possible. \nKIT members should use the KIT sign-in.",
    "passwordsDoNotMatch": "The given passwords do not match"
  },
  "labels": {
    "action": "Action",
    "add": "Add",
    "additionalactions": "Additional actions",
    "all": "All",
    "apply": "Apply",
    "begin": "Begin",
    "cancel": "Cancel",
    "changes": "Changes (German)",
    "close": "Close",
    "confirm": "Confirm",
    "contact": "Contact",
    "copy": "Copy",
    "create": "Create",
    "delete": "Delete",
    "discard": "Discard",
    "discardchanges": "Discard changes",
    "documentation": "Documentation",
    "duplicate": "Duplicate",
    "edit": "Edit",
    "end": "End",
    "help": "Help",
    "home": "Home",
    "merge": "Merge",
    "optimizecontinue": "Continue optimization",
    "optimizenew": "New optimization",
    "order": "Order",
    "pleaseselect": "Please select",
    "publish": "Publish",
    "publishchanges": "Publish changes",
    "regarding": "regarding",
    "save": "Save",
    "search": "Search",
    "sendmessage": "Send message",
    "settings": "Settings",
    "signin": "Sign in",
    "signout": "Sign Out",
    "signup": "Sign Up",
    "switchlanguage": "Wechsle zu Deutsch",
    "undelete": "Remove deletion marker",
    "update": "Update",
    "yourchoice": "Your selection"
  },
  "notifications": {
    "success": "The changes have been successful."
  },
  "terms": {
    "additem": "Add item",
    "additionalsettings": "Additional settings",
    "addperson": "Add person",
    "apply": "Apply",
    "close": "Close",
    "course": "Course",
    "date": "Date",
    "description": "Description",
    "difference": "Difference",
    "display": "Display",
    "export": "Export",
    "external": "External",
    "externaluser": "External user",
    "externalusers": "External Users",
    "item": "item",
    "items": "items",
    "kitmember": "KIT employee or student",
    "label": "Label",
    "language": "Language",
    "login": "Log In",
    "logout": "Log out",
    "matriculationId": "Matriculation Id",
    "members": "members",
    "message": "Message",
    "new": "new",
    "no": "No",
    "none_female": "none",
    "none_male": "none",
    "noresults": "No results",
    "note": "Note",
    "object": "Object",
    "of": "of",
    "or": "or",
    "participants": "Participants",
    "recipient": "Recipient",
    "recipients": "Recipients",
    "register": "Register",
    "requiredField": "Mandatory field",
    "result": "Result",
    "send": "Send",
    "subject": "Subject",
    "term": "Term",
    "unlimited": "unlimited",
    "unsaved": "unsaved",
    "yes": "Yes"
  },
  "time": {
    "ended": "ended",
    "in": "starts in {time}",
    "left": "{time} left",
    "unlimited": "unlimited"
  },
  "words": {
    "by": "by",
    "persons": "persons",
    "personssolo": "Persons"
  }
}
