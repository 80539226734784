{
  "features": {
    "quotes": {
      "dialogintro": "I, {name}, hereby submit the decision regarding the number of admissions or admission offers (DOSV) which the faculty would like to grant with the release of the ranking list for the following study programs:",
      "dialogtitle": "Transmission of the overbooking quota(s) for the university's own selection procedures"
    }
  },
  "labels": {
    "procedure": "Procedure"
  },
  "menu": {
    "labels": {
      "dossierdownloader": "Dossier downloader",
      "overview": "Overview",
      "procedures": "Procedures"
    }
  },
  "notifications": {
    "infoletter": {
      "delete": {
        "success": "The info letter for the {state} status has been deleted.",
        "text": "Do you really want to delete the info letter for the status \"{state}\"?",
        "title": "Delete infoletter"
      },
      "upload": {
        "success": "The info letter for the {state} status has been successfully uploaded.",
        "text": "You can create a new info letter for the specified status here. Please note that you should coordinate any changes to the content with Ms. Kurz (SLE) beforehand. Changes to dates, contact persons or links do not need to be cleared.",
        "title": "Upload info letter for state \"{state}\""
      }
    }
  }
}
